import React, { useMemo, useCallback } from "react";
import { handleCSVDownload, handleXlsxDownload } from "./utils";
import DownloadDropdown from "./DownloadDropdown";

/**
 * `TableDetails` is a functional component that displays a table
 *
 * @component
 * @param {Object} props - The properties that define the component.
 * @param {string} props.invoiceDetails - The invoice details in JSON format.
 * @param {function} props.setHighlightAreas - The function to set the highlight areas.
 * @returns {React.ElementType} Returns a table of invoice details if there are any, otherwise returns null.
 */

const TableDetails = ({ tableDetails, setHighlightAreas, fileID, jumpToPage }) => {
  const parsedTableDetails = useMemo(() => {
    if (!tableDetails) return [];

    return tableDetails;
  }, [tableDetails]);

  /**
   * Groups an array of objects by their 'row' property, organizing them into an object where each key corresponds to a unique row number and maps to an array of items that belong to that row.
   *
   * @param {Array<Object>} data - The input array of objects, each expected to have a 'row' property.
   * @returns {Object} An object with keys representing unique row numbers. Each key maps to an array of objects that share the same 'row' value.
   *
   * @example
   * // Example input data
   * const inputData = [
   *   { row: 1, col: 1, content: 'Item 1, Row 1' },
   *   { row: 1, col: 2, content: 'Item 2, Row 1' },
   *   { row: 2, col: 1, content: 'Item 1, Row 2' },
   *   { row: 3, col: 1, content: 'Item 1, Row 3' }
   * ];
   *
   * // Example call to groupByRows
   * const groupedData = groupByRows(inputData);
   *
   * // Expected output of groupedData
   * {
   *   '1': [{ row: 1, col: 1, content: 'Item 1, Row 1' }, { row: 1, col: 2, content: 'Item 2, Row 1' }],
   *   '2': [{ row: 2, col: 1, content: 'Item 1, Row 2' }],
   *   '3': [{ row: 3, col: 1, content: 'Item 1, Row 3' }]
   * }
   *
   * The function effectively organizes items into groups based on their row number, making it easier to process or display data that is logically segmented by rows.
   */
  const groupByRows = (data) => {
    const groupedData = data.reduce((acc, item) => {
      // Initialize the row array if it doesn't exist
      if (!acc[item.row]) {
        acc[item.row] = [];
      }

      // Push the current item to the appropriate row
      acc[item.row].push(item);
      return acc;
    }, {});
    // Sort each array within the groupedData object by the 'col' property
    // TODO: This line is key if the cols aren't sorted. Add failing tests if this next line is removed.
    Object.values(groupedData).forEach((array) => {
      array.sort((a, b) => a.col - b.col);
    });

    return groupedData;
  };


  const structuredData = useMemo(
    () => groupByRows(parsedTableDetails),
    [parsedTableDetails]
  );

  const handleCSVDownloadCallback = useCallback(() => {
    handleCSVDownload(transformTableForCSVDownload(parsedTableDetails));
  }, [parsedTableDetails]);

  const handleXlsxDownloadCallback = useCallback(() => {
    handleXlsxDownload(transformTableForCSVDownload(parsedTableDetails));
  }, [parsedTableDetails]);

  /**
   * Transforms a structured array of table data (from our backend table extraction) into a format suitable for CSV download.
   * The function processes input data to identify column headers and organize the content
   * by rows and columns. It ensures that each row of data aligns under the correct headers,
   * based on the 'col' and 'row' properties of each item in the inputData array.
   *
   * @param {Array<Object>} inputData - An array of objects representing table data, where each object
   * includes properties like 'col', 'row', 'content', and 'kind' ('columnHeader' or 'content') to
   * denote the nature of the table cell and its position.
   *
   * @returns {Array<Object>} An array of objects, each representing a row of the table, where keys
   * correspond to column headers and values to the cell content. This format is ready for conversion
   * to CSV, where each object in the array will correspond to a row in the CSV file, and object keys
   * will define the column headers.
   *
   * @example
   * const inputData = [
   *   { col: 0, row: 0, content: 'Header1', kind: 'columnHeader' },
   *   { col: 1, row: 0, content: 'Header2', kind: 'columnHeader' },
   *   { col: 0, row: 1, content: 'Row1Col1', kind: 'content' },
   *   { col: 1, row: 1, content: 'Row1Col2', kind: 'content' }
   * ];
   * const csvReadyData = transformTableForCSVDownload(inputData);
   * // csvReadyData will be:
   * // [
   * //   { 'Header1': 'Row1Col1', 'Header2': 'Row1Col2' }
   * // ]
   *
   * Note: The function assumes that the inputData is correctly structured with matching 'col' values for
   * headers and content. It will place empty strings in cells where content is missing for a given column
   * in a row, ensuring a consistent structure for CSV conversion.
   */
  const transformTableForCSVDownload = (inputData) => {

    // Identify Column Headers based on 'row' being 0
    const headers = inputData
      .filter((item) => item.row === 0)
      .sort((a, b) => a.col - b.col)
      .map((item) => item.content);

    // Initialize an object to hold row data
    let rowData = {};

    // Organize Data by Rows and Columns
    inputData.forEach((item) => {
      // Skip header rows for row data organization
      if (item.row > 0) {
        if (!rowData[item.row]) {
          rowData[item.row] = new Array(headers.length).fill(""); // Initialize empty array for row
        }
        rowData[item.row][item.col] = item.content;
      }
    });

    // Construct Objects for Each Row
    return Object.values(rowData).map((row) => {
      return row.reduce((obj, content, index) => {
        obj[headers[index]] = content;
        return obj;
      }, {});
    });
  };
  
  const setHighlightAreasInternal = (cell) => {
    const pageNumber = cell.page_number - 1;
    setHighlightAreas([
      {
        file_id: fileID,
        pageIndex: pageNumber,
        height: (cell.height / cell.page_height) * 100,
        width: (cell.width / cell.page_width) * 100,
        left: (cell.left / cell.page_width) * 100,
        top: (cell.top / cell.page_height) * 100,
      },
    ]);
    jumpToPage(pageNumber);
  }

  return (
    Math.max(...tableDetails.map((item) => item.row)) > 0 && ( // Here we ensure that the table has at least one row (not including the header row.)
      <>
        <table className="table-auto w-full">
          <thead>
            <tr className="border-b border-gray-300">
              {structuredData[0].map((cell, index) => (
                <th
                  onMouseEnter={() =>
                    setHighlightAreasInternal(cell)
                  }
                  key={index}
                  className="min-w-[150px]  hover:bg-gray-200 cursor-pointer"
                >
                  {cell.content}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(structuredData).map((rowKey) => {
              if (parseInt(rowKey) === 0) return null;

              return (
                <tr key={rowKey} className="border-b ">
                  {structuredData[rowKey].map((cell, index) => (
                    <td
                      key={index}
                      className="text-sm py-2 border-gray-300 hover:bg-gray-200 cursor-pointer"
                      onMouseEnter={() =>
                        setHighlightAreasInternal(cell)
                      }
                    >
                      {cell.content}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <DownloadDropdown
          handleCSVDownloadCallback={handleCSVDownloadCallback}
          handleXlsxDownloadCallback={handleXlsxDownloadCallback}
        />
        <br />
      </>
    )
  );
};

export default TableDetails;
