import { useState, useEffect } from "react";
import { postRequest } from "../../utils/httpUtils";
import TextEditor from "../../utils/TextEditor";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export default function GenerateFootnote({
  selectedTemplate,
  successfulFileUploadsFromUploadData,
  appInstanceID,
}) {
  const [footnotes, setFootnotes] = useState(null);
  const [approved, setApproved] = useState(false);

  const generateFootnote = async () => {
    const fileIDs = successfulFileUploadsFromUploadData.map(
      (file) => file.data.file_id
    );
    try {
      const response = await postRequest(
        "/api/footnote_ai/generate_footnotes",
        {
          footnote_id: selectedTemplate,
          file_ids: fileIDs,
          app_instance_id: appInstanceID,
        }
      );

      console.log(response.data.data);
      setFootnotes(response.data.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
      console.log(error.config);
    }
  };

  useEffect(() => {
    console.log("XG selectedTemplate", selectedTemplate);
    console.log(
      "XG successfulFileUploadsFromUploadData",
      successfulFileUploadsFromUploadData
    );
    if (selectedTemplate && successfulFileUploadsFromUploadData.length > 0) {
      setFootnotes("Generating footnote...");
      generateFootnote();
    } else {
      setFootnotes(
        "NOT generating footnote because required information not provided."
      );
    }
  }, [selectedTemplate, successfulFileUploadsFromUploadData]);

  return (
    <div className="flex flex-col items-start items-baseline md:flex-row p-6 min-h-screen">
      {/* Left Section - Suggested Footnotes */}
      <div className="w-full bg-white p-4 shadow-lg rounded-md">
        {/* md:w-2/4 for half width */}
        <h1>Generate Footnote</h1>
        <TextEditor
          text={footnotes}
        />
        <button
          className={`items-center pt-2 pb-2 px-4 font-normal rounded border-1 cursor-pointer justify-center no-underline ${
            approved
              ? "bg-green-500 text-white border-green-900"
              : "hover:bg-customHighlightColor text-customHighlightColor border-customHighlightColor"
          } hover:text-customLightGray transition-colors duration-300`}
          onClick={() => setApproved(true)}
        >
          {approved ? "Approved" : "Approve"}
        </button>
      </div>
      {/* Right Section - Details Panel */}
      {/* <div className="w-full md:w-2/4 bg-white p-4 shadow-lg rounded-md md:ml-4 md:mt-0"> */}
      {/* <h1>Formatted Output</h1>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            table: ({ node, ...props }) => (
              <table
                className="table-auto border-collapse border border-gray-300 w-full"
                {...props}
              />
            ),
            th: ({ node, ...props }) => (
              <th
                className="border border-gray-300 px-4 py-2 bg-gray-100"
                {...props}
              />
            ),
            td: ({ node, ...props }) => (
              <td className="border border-gray-300 px-4 py-2" {...props} />
            ),
          }}
        >
          {footnotes}
        </ReactMarkdown>
        {/* <div className="flex justify-between items-center border-b py-3">
          <h4 className="text-lg font-bold text-customBlack">Footnote 01</h4>
          <div className="flex items-center space-x-1">
            <span className="text-sm text-blue-500 cursor-pointer">
              <XMarkIcon className="w-6 h-6 cursor-pointer text-customHighlightColor" />
            </span>
          </div>
        </div>
        <div className="w-full mt-6">
          <Table
            colNames={["Suggested disclosure", "Status"]}
            rows={[
              {
                row_data: [
                  "1 Notes and accounts receivable",
                  <font color="red">Awaiting approval</font>,
                ],
                metadata: { bgcolor: "gray" },
              },
              {
                row_data: [
                  "2 Notes and accounts receivable",
                  "Approved by Angela Kimberly on 23/08 at 11:58 AM",
                ],
                metadata: { bgcolor: "green" },
              },
              {
                row_data: [
                  "3 Notes and accounts receivable",
                  "Awaiting approval",
                ],
              },
              {
                row_data: [
                  "4 Investments in debt and equity",
                  <font color="green">Approved</font>,
                ],
              },
              {
                row_data: [
                  "5 Notes and accounts receivable",
                  "Awaiting approval",
                ],
                metadata: { bgcolor: "gray" },
              },
              {
                row_data: [
                  "6 Investments in debt and equity",
                  "Approved by Angela Kimberly on 02/09 at 11:23 AM",
                ],
                metadata: { bgcolor: "green" },
              },
              {
                row_data: [
                  "7 Notes and accounts receivable",
                  "Awaiting approval",
                ],
              },
              {
                row_data: [
                  "8 Investments in debt and equity",
                  "Awaiting approval",
                ],
                metadata: { bgcolor: "gray" },
              },
              { row_data: ["9 Notes and accounts receivable", "In Progress"] },
              { row_data: ["10 Notes and accounts receivable", "In Progress"] },
              {
                row_data: [
                  "11 Notes and accounts receivable",
                  "Awaiting approval",
                ],
              },
              {
                row_data: [
                  "12 Notes and accounts receivable",
                  "Approved by Angela Kimberly on 23/08 at 11:58 AM",
                ],
                metadata: { bgcolor: "green" },
              },
              {
                row_data: [
                  "13 Notes and accounts receivable",
                  "Awaiting approval",
                ],
                metadata: { bgcolor: "blue" },
              },
              { row_data: ["14 Investments in debt and equity", "Approved"] },
              {
                row_data: [
                  "15 Notes and accounts receivable",
                  "Awaiting approval",
                ],
              },
              {
                row_data: [
                  "16 Investments in debt and equity",
                  "Approved by Angela Kimberly on 02/09 at 11:23 AM",
                ],
                metadata: { bgcolor: "green" },
              },
              {
                row_data: [
                  "17 Notes and accounts receivable",
                  "Awaiting approval",
                ],
              },
              {
                row_data: [
                  "18 Investments in debt and equity",
                  "Awaiting approval",
                ],
                metadata: { bgcolor: "pink" },
              },
              { row_data: ["19 Notes and accounts receivable", "In Progress"] },
            ]}
            itemsPerPage={5}
          />
        </div> */}
      {/* </div> */}
    </div>
  );
}
