import React, { useEffect, useState, useRef, useCallback } from "react";
import { postRequest, ssePostRequest } from "../utils/httpUtils";
import { useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  NotificationType,
  useNotifications,
} from "../utils/notifications/Notifications";
import { secureFilename, isTellenUser } from "../utils";
import DynamicForm from "../DynamicForm";
import { parseFile } from "../utils/parseFile";
import { handleURLSubmit } from "../utils/handleURLSubmit";
import {
  getMyApps,
  getApp,
  appSelector,
  getFilesByAppId,
  filesByAppSelector,
  updateApp,
} from "../redux/reducers/apps.reducer";
import { customizationsSelector } from "../redux/reducers/app.reducer";
import { useAppInstanceData } from "../hooks/useAppInstanceData";
import { handleFileUpload } from "../utils/FileUpload/handleFileUpload";
import ApprovedQuestionsAnswers from "../apps/generalDataChatAnalyzer/ApprovedQuestionsAnswers";
import DeleteAppButton from "../utils/DeleteAppButton";
import { extractData } from "../utils/extractData";

const EditApp = () => {
  const dispatch = useDispatch();
  const urlInput = useRef(null);
  const { addNotification } = useNotifications();
  const navigate = useNavigate();
  const { user } = useUser();
  const appData = useSelector(appSelector);
  const appFiles = useSelector(filesByAppSelector);
  const appCustomizations = useSelector(customizationsSelector);

  const [inputValue, setInputValue] = useState("");
  const [enableGlobalValue, setEnableGlobalValue] = useState(false);
  // Initialize dynamicFields with an empty object or structured data but without pre-filled values
  const [dynamicFields, setDynamicFields] = useState({});
  // Use another state to track the values of the dynamic fields
  const [dynamicValues, setDynamicValues] = useState({});
  const [created, setCreated] = useState(false);
  const [filesDropped, setFilesDropped] = useState(false);
  const [fileIDs, setFileIDs] = useState([]); // I think we can kill this variable
  const [successfulFileUploads, setSuccessfulFileUploads] = useState([]);
  const [currentPDFURL, setCurrentPDFURL] = useState(null); // TODO: This should not be necessary here at all, as we're not selecting individual rows.
  const [urlInputSubmitPressed, setURLInputSubmitPressed] = useState(false);
  const [pdfDetails, setPDFDetails] = useState(null);
  const [filesParsing, setFilesParsing] = useState([]);
  const [filesUploading, setFilesUploading] = useState([]);
  const { appInstanceData: appInstance, instanceId: app_instance_id } =
    useAppInstanceData();

  useEffect(() => {
    if (appInstance && appInstance.app_id) {
      dispatch(getApp({ id: appInstance.app_id }));
      dispatch(getFilesByAppId(appInstance.id));
      setInputValue(appInstance.custom_name);
      setDynamicFields(appInstance.customization_template);
      setDynamicValues(appInstance.customizations);
      setEnableGlobalValue(appInstance.enable_global);
    }
  }, [appInstance]);

  useEffect(() => {
    if (appFiles)
      setSuccessfulFileUploads(appFiles.map((file) => ({ data: file })));
  }, [appFiles]);

  const handleDynamicInputChange = (e) => {
    const { id, value } = e.target;
    setDynamicValues((prev) => ({ ...prev, [id]: value }));
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      let name = inputValue || `${appData.name}`;
      const payload = {
        name: name,
        user: user,
        app_id: appData.id,
        customizations: dynamicValues,
        enableGlobal: enableGlobalValue,
      };
      const response = await dispatch(
        updateApp({ appId: app_instance_id, payload })
      );
      setInputValue("");
      setCreated(true);
      await dispatch(getMyApps({ user }));
      navigate(response.payload.path);
    } catch (error) {
      console.error("Failed to create app:", error);
    }
  };

  const catchAction = ({ fileID }) => {
    setFileIDs((fileIDs) => fileIDs.filter((id) => id !== fileID));
    setSuccessfulFileUploads((successfulFileUploads) =>
      successfulFileUploads.filter((file) => file.data["file_id"] !== fileID)
    );
    setFilesParsing((previousFiles) =>
      previousFiles.filter((id) => id !== fileID)
    );
  };
  const beforePostAction = ({ file }) => {
    setFilesUploading((previousFiles) => [...previousFiles, file]);
  };
  const finalAction = ({ fileID }) => {
    setFilesParsing((previousFiles) =>
      previousFiles.filter((id) => id !== fileID)
    );
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      const preAction = () => {
        setPDFDetails(null);
        setFilesDropped(true);
      };

      const thenAction = ({ fileID, response, file }) => {
        setFilesUploading((previousFiles) =>
          previousFiles.filter((f) => f !== file)
        );
        setFileIDs([fileID]);
        setSuccessfulFileUploads((previousUploads) => [
          ...previousUploads,
          response,
        ]);
        setURLInputSubmitPressed(false);
        console.log("APP DATA SLUG, ", appData.slug);
        setFilesParsing((previousFiles) => [...previousFiles, fileID]);
        if (appData.slug != "footnote_ai") {
          parseFile(
            fileID,
            "pdf",
            postRequest,
            setPDFDetails,
            addNotification,
            NotificationType,
            { catchAction, finalAction }
          );
        } else {
          // COPIED FROM GENERICEXTRACTOR.JS WHEN LOOKING AT 10Ks. TODO: Refactor this into a function.
          console.log("FILEID: ", fileID)
          try {
            extractData(fileID,
              {
                onStatus: (jsonPayload) => {
                  console.log("Status:", jsonPayload);
                },
                onFinal: (jsonPayload) => {
                  console.log("Final:", jsonPayload);
                  if ("error" in jsonPayload.value) {
                    addNotification(
                      "No data found.",
                      "",
                      NotificationType.error
                    );
                  } else {
                    // addNewElement(fileID, {
                    //   financialStatements:
                    //     jsonPayload.value["financial_statements"],
                    //   footnotes: jsonPayload.value["footnotes"],
                    // });
                    // selectElementByFileID(fileID);
                    addNotification(
                      "Document parsed.",
                      "",
                      NotificationType.success
                    );
                    finalAction({ fileID });
                  }
                },
                onError: (error) => {
                  console.error("Error:", error);
                  addNotification(
                    "Error parsing document.",
                    "",
                    NotificationType.error
                  );
                },
              }
            );
          } catch (error) {
            addNotification(
              "Error submitting your query.",
              "",
              NotificationType.error
            );
            console.error("Error fetching data:", error);
          }
        }
      };

      handleFileUpload(
        acceptedFiles,
        { addNotification, fileType: "pdf", user },
        { preAction, thenAction, catchAction, beforePostAction }
      );
    },
    [fileIDs, user]
  );

  useEffect(() => {
    if (successfulFileUploads.length > 0) {
      setFileIDs(successfulFileUploads.map((file) => file.data["file_id"]));
      setCurrentPDFURL(successfulFileUploads[0]["url"]); // TODO: THis doesn't need to be set again and again, but it's okay for now.

      setDynamicValues((prev) => {
        return {
          ...prev,
          fileIDs: successfulFileUploads.map((file) => file.data["file_id"]),
        };
      });
    } else {
      setCurrentPDFURL(null);
      setPDFDetails(null);
      setFileIDs([]);
      setFilesDropped(false);
    }
  }, [successfulFileUploads]);

  const navigateToPublishApp = useCallback(() => {
    navigate(`/app/publish/${app_instance_id}`);
  }, [navigate, app_instance_id]);

  const isParsing =
    (fileIDs.length > 0 && filesParsing.length > 0) ||
    (urlInputSubmitPressed && !currentPDFURL);
  const isUploading = filesUploading.length > 0;

  return (
    appInstance &&
    appData && (
      <div className="flex flex-col gap-4">
        <div className="bg-white">
          <h1 className="text-2xl font-bold">
            Edit App: <b>{appData.name}</b>
          </h1>
          <p className="mb-5">{appData.description}</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {!created ? (
            <form onSubmit={handleEdit} className="col-span-full">
              <label className="block text-gray-700 font-bold pr-4">
                Name:
              </label>
              <input
                type="text"
                className="shadow appearance-none border rounded w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder={`${appData.name}`}
                value={inputValue}
                onChange={({ target }) => setInputValue(target.value)}
              />
              {(appCustomizations.environment !== "production" ||
                isTellenUser(user)) && (
                <>
                  <br />
                  <div className="mt-4">
                    <input
                      type="checkbox"
                      id="enableGlobalCheckbox"
                      name="enableGlobalCheckbox"
                      checked={enableGlobalValue}
                      onChange={({ target }) =>
                        setEnableGlobalValue(target.checked)
                      }
                      value="selected"
                    />
                    <label
                      htmlFor="enableGlobalCheckbox"
                      className="ml-2 text-gray-700"
                    >
                      Share app with colleagues.
                    </label>
                  </div>
                </>
              )}
              <br />
              <br />
              <DynamicForm
                dynamicFields={dynamicFields}
                dynamicValues={dynamicValues}
                onInputChange={handleDynamicInputChange}
                // This list of parameters is too long
                onDrop={onDrop}
                urlInput={urlInput}
                showDelete={true}
                fileIDs={fileIDs}
                isParsing={isParsing}
                isUploading={isUploading}
                handleURLSubmit={(event) =>
                  handleURLSubmit(event, {
                    urlInput,
                    uuidv4,
                    secureFilename,
                    postRequest,
                    user,
                    setFileIDs,
                    parseFile,
                    setSuccessfulFileUploads,
                    setURLInputSubmitPressed,
                    addNotification,
                    NotificationType,
                    setPDFDetails,
                    catchAction,
                    finalAction,
                  })
                }
                filesDropped={filesDropped}
                successfulFileUploads={successfulFileUploads}
                currentPDFURL={currentPDFURL}
                setCurrentPDFURL={setCurrentPDFURL}
                setSuccessfulFileUploads={setSuccessfulFileUploads}
                urlInputSubmitPressed={urlInputSubmitPressed}
              />

              {appData.slug === "general_data_chat" && (
                <>
                  <hr />
                  <ApprovedQuestionsAnswers
                    instance_id={app_instance_id}
                    successfulFileUploads={successfulFileUploads}
                    dynamicValues={dynamicValues}
                  />
                </>
              )}
              {appData.slug === "footnote_ai" && (
                <>
                  <hr />
                  {/* Here we put the table specific to FootnoteAI */}
                </>
              )}
              <div className="flex items-center gap-2">
                <button
                  type="submit"
                  className="flex items-center pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed disabled:border-gray-300 disabled:hover:bg-gray-300 disabled:hover:text-gray-500 disabled:transition-colors disabled:duration-300"
                  disabled={isParsing}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={navigateToPublishApp}
                  className="flex items-center pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed disabled:border-gray-300 disabled:hover:bg-gray-300 disabled:hover:text-gray-500 disabled:transition-colors disabled:duration-300"
                  disabled={isParsing}
                >
                  Publish App
                </button>
                {!appInstance.enable_global && (
                  <DeleteAppButton app_instance_id={app_instance_id} />
                )}
              </div>
            </form>
          ) : null}
        </div>
      </div>
    )
  );
};

export default EditApp;
