import React, { useState } from "react";
import { getBaseURL } from "../../utils";

const FootnotesTable = ({ footnotes }) => {
  const [expandedRows, setExpandedRows] = useState({});
  const handleRowClick = (id) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [id]: !prevExpandedRows[id],
    }));
  };
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="px-1 py-1 text-left text-sm font-semibold text-gray-900"
          >
            Source File
          </th>
          <th
            scope="col"
            className="px-1 py-1 text-left text-sm font-semibold text-gray-900"
          >
            Number
          </th>
          <th
            scope="col"
            className="px-1 py-1 text-left text-sm font-semibold text-gray-900"
          >
            Title
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {footnotes.map((footnote) => (
          <React.Fragment key={footnote.id}>
            <tr
              onClick={() => handleRowClick(footnote.id)}
              className="cursor-pointer hover:bg-gray-100"
            >
              <td className="whitespace-nowrap px-1 py-1 text-sm text-gray-500">
                <a
                  href={`${getBaseURL()}/api/files/${footnote.pdf_file_id}.pdf`}
                >
                  {footnote.pdfFile?.filename}
                </a>
              </td>
              <td className="whitespace-nowrap px-1 py-1 text-sm text-gray-500">
                {footnote.number + 1}
              </td>
              <td className="whitespace-nowrap px-1 py-1 text-sm text-gray-500">
                {footnote.footnote.title}
              </td>
            </tr>
            {expandedRows[footnote.id] && (
              <tr>
                <td colSpan="3" className="bg-gray-50">
                  <div className="py-2 px-4">
                    {footnote.footnote?.paragraphs.map((p, index) => (
                      <p key={index}>{p.text}</p>
                    ))}
                  </div>
                  <div className="py-2 px-4">
                    <img
                      src={`${getBaseURL()}/api/files/${footnote.screenshot_file_id
                        }`}
                      alt="Footnote Screenshot"
                      className="w-120 h-120 object-cover"
                    />
                  </div>
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default FootnotesTable;
