import React, {useState} from 'react';
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  
  PlusCircleIcon,
  PaperClipIcon,
  XMarkIcon,
  TrashIcon
  
} from '@heroicons/react/24/solid';
import { ClockIcon,FunnelIcon,CheckCircleIcon } from '@heroicons/react/24/outline';
import Table from './Table';

const ChooseFootnote = () => {
      const [hoveredRow, setHoveredRow] = useState(null);
      const [currentPage, setCurrentPage] = useState(1);
      const itemsPerPage = 6;

      const [rows, setRows] = useState([
        { id: 0, row_data: ["Operating Lease Disclosure", "Approved"], metadata: { bgcolor: "#dff5e1" } },
        // { id: 1, row_data: ["Notes and accounts receivable", "Awaiting approval"], metadata: { bgcolor: "white" } },
        // { id: 2, row_data: ["Investments in debt and equity securities", "Approved"], metadata: { bgcolor: "#dff5e1" } },
        // { id: 3, row_data: ["Credit losses on financial instruments", "Approved"], metadata: { bgcolor: "#dff5e1" } },
        // { id: 4, row_data: ["Credit Quality information", "Awaiting approval"], metadata: { bgcolor: "white" } },
        // { id: 5, row_data: ["Allowance for credit losses", "Awaiting approval"], metadata: { bgcolor: "white" } },
        // { id: 6, row_data: ["Purchase financial assets with credit deterioration", "Awaiting approval"], metadata: { bgcolor: "white" } },
        // { id: 7, row_data: ["Collateral-dependent financial assets", "Awaiting approval"], metadata: { bgcolor: "white" } },
        // { id: 8, row_data: ["Off-balance-sheet-credit exposure", "Awaiting approval"], metadata: { bgcolor: "white" } },
        // { id: 9, row_data: ["Transition Disclosures", "Awaiting approval"], metadata: { bgcolor: "white" } },
      ]);
      const handleStatusChange = (index, newStatus) => {
        const updatedRows = rows.map((row, idx) =>
          idx === index
            ? {
                ...row,
                row_data: [row.row_data[0], newStatus],
                metadata: { bgcolor: newStatus === "Approved" ? "#dff5e1" : "white" },
              }
            : row
        );
        setRows(updatedRows);
      };
    
      const handleDeleteRow = (index) => {
        const updatedRows = rows.filter((_, idx) => idx !== index);
        setRows(updatedRows);
      };
    
      // Menu for hover options
      const getHoverMenu = (index) => (
        <div className="absolute right-0 flex space-x-2 bg-white border border-gray-300 rounded-md shadow-lg p-2 z-10">
          <button
            key={`approve-${index}`}
            onClick={() => handleStatusChange(index, "Approved")}
            className="flex items-center text-customHighlightColor text-sm"
          >
            <CheckCircleIcon className="w-5 h-5 mr-1" />
          </button>
          <button
            key={`awaiting-${index}`}
            onClick={() => handleStatusChange(index, "Awaiting approval")}
            className="flex items-center text-blue-800 text-sm"
          >
            <ClockIcon className="w-5 h-5 mr-1" /> 
          </button>
          <button
            key={`delete-${index}`}
            onClick={() => handleDeleteRow(index)}
            className="flex items-center text-red-500 text-sm"
          >
            <TrashIcon className="w-5 h-5 mr-1" /> 
          </button>
        </div>
      );
    
      // Pagination
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentRows = rows.slice(indexOfFirstItem, indexOfLastItem);
    
      const totalPages = Math.ceil(rows.length / itemsPerPage);
    
      const paginate = (pageNumber) => setCurrentPage(pageNumber);
        
    return (
        <div className="flex flex-col items-baseline md:flex-row p-6 min-h-screen">
        {/* Left Section - Suggested Footnotes */}
          <div className="w-full md:w-1/3 bg-white p-4 shadow-lg rounded-md">
          <div className="flex items-center justify-between mb-2">
            {/* Main Title and Count */}
            <div className="flex flex-col w-full">
              <div className="flex items-center ">
                <h3 className="text-lg font-bold text-customBlack">Suggested Footnotes</h3>
                <div className="flex items-center bg-blue-50 text-blue-500 text-sm font-semibold px-2 py-1 rounded-md">
                <ClockIcon className="w-4 h-4 mr-1" /> In progress
                </div>
              </div>
              <div className="flex items-center justify-between mt-2">
                <div className="relative w-full bg-customLightGray h-1 rounded">
                  <div className="bg-customHighlightColor h-1 rounded" style={{ width: '20%' }}></div>
                </div>
                <span className="text-sm text-customDarkGray ml-2">(3/15)</span>
              </div>
            </div>
          </div>
                    


          {/* Tags, Filter, and Add Button */}
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center space-x-2">
              <button className="text-sm text-customHighlightColor border border-customHighlightColor bg-customHighlightColor/10 rounded-md px-2 py-1">15 All</button>
              <button className="text-sm text-customDarkGray border border-gray-300 rounded-md px-2 py-1">03 Approved</button>
              <button className="text-sm text-customDarkGray border border-gray-300 rounded-md px-2 py-1">12 Awaiting approval</button>
            </div>
            <div className="flex items-center space-x-3">
              <FunnelIcon className="w-6 h-6 text-customHighlightColor cursor-pointer"  />
              <PlusCircleIcon className="w-7 h-7 text-customHighlightColor cursor-pointer" />
            </div>
          </div>

          {/* Disclosure List */}            
          <div className="w-full mt-6 relative">
          <table className="min-w-full bg-white shadow-lg rounded-md border ">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text font-bold text-gray-700 tracking-wider">Disclosure Type</th>
                <th className="px-6 py-3 text-left text font-bold text-gray-700 tracking-wider">Status</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {currentRows.map((row, index) => (
                <tr
                  key={row.id}
                  className={`border-b  ${hoveredRow === index ? 'relative' : ''}`}
                  style={{ backgroundColor: row.metadata.bgcolor }}
                  onMouseEnter={() => setHoveredRow(index)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <td className="px-6 py-4 text-sm text-gray-500 font-size font-bold">{row.row_data[0]}</td>
                  <td className="px-6 py-4 text-sm relative">
                    {hoveredRow === index ? (
                      getHoverMenu(index + (currentPage - 1) * itemsPerPage)
                    ) : row.row_data[1] === "Approved" ? (
                      <span className="flex items-center text-green-400 font-semibold">
                        <CheckCircleIcon className="w-4 h-4 mr-1 " /> Approved
                      </span>
                    ) : (
                      <span className="flex items-center text-blue-500 font-semibold">
                        <ClockIcon className="w-4 h-4 mr-1 " /> Awaiting approval
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

          {/* Pagination */}
          <div className="flex justify-end items-center mt-4 space-x-2 border-customHighlightColor ">
          <button
            className={`text-sm p-2 border-customHighlightColor rounded-md ${currentPage === 1 ? 'text-gray-400 cursor-not-allowed' : 'text-blue-500'}`}
            disabled={currentPage === 1}
            onClick={() => paginate(currentPage - 1)}
          >
            <ChevronLeftIcon className="w-4 h-4 text-customHighlightColor" />
          </button>
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i}
              className={`text-sm px-3 py-1 border-customHighlightColor rounded-md ${currentPage === i + 1 ? 'bg-purple-100 text-customHighlightColor' : 'text-gray-700 border border-customHighlightColor'}`}
              onClick={() => paginate(i + 1)}
            >
              {String(i + 1).padStart(2, '0')}
            </button>
          ))}
          <button
            className={`text-sm p-2 border-customHighlightColor rounded-md ${currentPage === totalPages ? 'text-gray-400 cursor-not-allowed' : 'text-blue-500'}`}
            disabled={currentPage === totalPages}
            onClick={() => paginate(currentPage + 1)}
          >
            <ChevronRightIcon className="w-4 h-4 text-customHighlightColor" />
          </button>
        </div>
      </div>

        {/* Right Section - Details Panel */}
        <div className="w-full md:w-2/3 bg-white p-4 shadow-lg rounded-md md:ml-4 mt-4 md:mt-0">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-lg font-bold text-customBlack">Details</h4>
            <div className="flex items-center space-x-1">
              <span className="text-blue-500">
                <XMarkIcon className="w-6 h-6 text-customHighlightColor cursor-pointer" />
              </span>
            </div>
          </div>

          {/* Disclosure Details */}
          <div className="mt-4 mb-6">
            <p className="text-base text-customBlack font-semibold">
              Disclosure Description:
            </p>
            <div className="border border-gray-300 p-2 rounded-md mt-1 bg-gray-50">
            <p className="mt-2 text-base text-customBlack">
              This disclosure describes the company's operating lease for office
              space, including its financial impact and the adoption of the new
              lease accounting standard ASC 842.
            </p>
            </div>
          </div>

          {/* Checklist section */}
          <div className="mb-4 mt-6">
          <div className="flex justify-between items-center">
            <p className="text-base font-semibold text-customBlack">
                Regulatory Compliance:
              </p>
              <div className="flex items-center space-x-1">
                <span className="text-sm text-blue-500 cursor-pointer text-customHighlightColor">
                  Check on file
                </span>
                <PaperClipIcon className="w-5 h-5 text-customHighlightColor cursor-pointer" />
              </div>
            </div>
            <div className="bg-gray-50 p-4 mt-2 rounded-md border-l-4 border-gray-500">
            <p className="text-sm text-customDarkGray font-semibold">
              This disclosure will comply with ASC 842-20-50 by providing
              qualitative and quantitative details about the company's operating
              lease, including its initial recognition, lease costs, maturity
              analysis, key judgments, and supplemental information, to help
              users understand its financial impact.
            </p>
            </div>
            {/* Practical Considerations List */}
            {/* <div className="bg-gray-50 p-4 mt-2 rounded-md border-l-4 border-blue-500">
              <p className="text-sm text-customDarkGray font-semibold">
                Practical Considerations:
              </p>
              <ul className="list-disc list-inside mt-2 text-sm text-customBlack">
                <li>
                  This disclosure applies after the adoption of ASU 2016-13,
                  Financial Instruments—Credit Losses (Topic 326): Measurement
                  of Credit Losses on Financial Instruments, and replaces the
                  disclosure in Question No. 4. The effective dates of the ASU
                  are specified for Credit Losses on Financial Instruments
                  Measured at Amortized Cost—For Years Beginning After December
                  15, 2022.
                </li>
              </ul>
            </div> */}
          </div>

          {/* Companies */}
          {/* <div className="mt-4">
            <p className="text-sm text-customDarkGray font-semibold">
              Companies:
            </p>
            <ul className="space-y-4 mt-2">
              <li className="flex items-center justify-between border-b pb-2">
                <div className="flex items-center space-x-3">
                  <img
                    src="https://place-hold.it/24"
                    alt="Company logo"
                    className="w-6 h-6"
                  />
                  <span className="text-sm text-customBlack">Google</span>
                </div>
                <button className="text-purple-500 hover:text-purple-700">
                  <ChevronRightIcon className="w-6 h-6" />
                </button>
              </li>
              <li className="flex items-center justify-between border-b pb-2">
                <div className="flex items-center space-x-3">
                  <img
                    src="https://place-hold.it/24"
                    alt="Company logo"
                    className="w-6 h-6"
                  />
                  <span className="text-sm text-customBlack">Amazon</span>
                </div>
                <button className="text-purple-500 hover:text-purple-700">
                  <ChevronRightIcon className="w-6 h-6" />
                </button>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    );
};

export default ChooseFootnote;
