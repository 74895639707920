import React from "react";

/**
 * URLSubmitForm is a React component that renders a form with an input field for URL submission.
 * It is designed to be used in scenarios where users need to submit a URL for processing.
 *
 * @component
 * @example
 * // Example usage:
 * const urlInputRef = React.useRef(null);
 * <URLSubmitForm
 *   handleURLSubmit={(event) => {
 *     event.preventDefault();
 *     console.log('URL submitted:', urlInputRef.current.value);
 *   }}
 *   urlInput={urlInputRef}
 *   buttonText="Submit URL"
 *   placeholderText="Enter a URL here..."
 * />
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.handleURLSubmit - The function to handle the form submission.
 * @param {React.RefObject} props.urlInput - A ref object created with useRef for the URL input field.
 * @param {string} [props.buttonText="Get PDF"] - The text to display on the submit button.
 * @param {string} [props.placeholderText="Or enter a URL..."] - The placeholder text for the URL input field.
 */
export const URLSubmitForm = ({
  handleURLSubmit,
  urlInput,
  buttonText = "Get",
  placeholderText = "Or enter a URL...",
}) => {
  return (
    <div className="flex gap-2 w-full">
      <input
        type="url"
        pattern="https?://.+"
        ref={urlInput}
        className="shadow appearance-none border rounded w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-none"
        placeholder={placeholderText}
        onKeyDown={(ev) => ev.key === "Enter" ? handleURLSubmit(ev) : () => {}}
      />
      <button
        onClick={(e) => handleURLSubmit(e)} // Use onClick here to trigger submission
        className="flex items-center pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
      >
        {buttonText}
      </button>
    </div>
  );
};

export default URLSubmitForm;
