/**
 * Handles the submission of a URL for file upload processing.
 *
 * This function initiates the process of uploading a file from a given URL. It first prevents the default form submission behavior,
 * then performs a series of actions such as generating a unique filename, making a POST request to the server with the URL and
 * file ID, and finally processes the response to either parse the PDF file or update the UI accordingly.
 *
 * @param {Event} event - The submit event triggered by the form submission.
 * @param {Object} context - An object containing various functions and values needed for the upload process.
 * @param {Function} context.addNotification - A function to display notification messages to the user.
 * @param {Function} context.beforeFinalAction - A function to execute just before the final action of the upload process. Useful for performing cleanup or additional checks.
 * @param {Function} context.catchAction - A function to handle errors during the upload process.
 * @param {Function} context.finalAction - A function to perform any final actions after the upload process completes.
 * @param {Object} context.NotificationType - An enumeration of notification types for categorizing notifications.
 * @param {Function} context.parseFile - A function to parse and process the uploaded file.
 * @param {Function} context.postRequest - A function to make HTTP POST requests to the server.
 * @param {Function} context.preAction - A function to execute before the upload process begins.
 * @param {Function} context.secureFilename - A function to generate a secure filename for the uploaded file.
 * @param {Function} context.setFileIDs - A function to update the list of file IDs associated with the upload session.
 * @param {Function} context.setPDFDetails - A function to update the details of the uploaded PDF file.
 * @param {Function} context.setSuccessfulFileUploads - A function to track successful file uploads.
 * @param {Function} context.setURLInputSubmitPressed - A function to indicate whether the URL input submit button has been pressed.
 * @param {Object} context.thenAction - A function to execute after the upload process completes successfully.
 * @param {React.RefObject<HTMLInputElement>} context.urlInput - A React ref object pointing to the URL input field.
 * @param {Object} context.user - User-related data, possibly including authentication tokens or identifiers.
 * @param {Function} context.uuidv4 - A function to generate a universally unique identifier (UUID).
 * @returns {Promise<void>} A promise that resolves when the upload process is complete or rejects if there was an error during the process.
 */
export const handleURLSubmit = async (event, context) => {
  const {
    addNotification,
    beforeFinalAction,
    catchAction,
    finalAction,
    NotificationType,
    parseFile,
    postRequest,
    preAction,
    secureFilename,
    setFileIDs, // NOTE: This variable is deprecated and should be removed from the context
    setPDFDetails,
    setSuccessfulFileUploads,
    setURLInputSubmitPressed,
    thenAction,
    urlInput,
    user,
    uuidv4,
  } = context;

  if (event) {
    event.preventDefault();
  }
  setURLInputSubmitPressed(true);

  const fileID = secureFilename(uuidv4()); // We should never have to use this on the frontend, so do the below everywhere it appears.
  const url = urlInput.current.value;
  
  if (preAction) {
    preAction({ url });
  }

  const finalActionInternal = (...args) => {
    if (beforeFinalAction) {
      beforeFinalAction({ url });
    }
    if (finalAction) {
      finalAction(...args);
    }
  }

  postRequest("/api/upload_from_url", {
    url: url,
    fileID: fileID,
    user: user,
  })
    .then((response) => {
      setSuccessfulFileUploads((previousUploads) => [
        ...previousUploads,
        response,
      ]);
      if (response.data["filetype"] === "pdf") {
        setFileIDs && setFileIDs([fileID]); // We should set this based upon the response, not the fileID: response.data["file_id"]
        parseFile(
          fileID, // We should set this based upon the response, not the fileID: response.data["file_id"]
          response.data["filetype"],
          postRequest,
          setPDFDetails,
          addNotification,
          NotificationType,
          { catchAction, finalAction: finalActionInternal }
        );
      } else {
        setFileIDs && setFileIDs([url]); // Is this right? Should it be the URL here?
        parseFile(
          response.data["file_id"], // This is good.
          response.data["filetype"],
          postRequest,
          setPDFDetails,
          addNotification,
          NotificationType,
          { catchAction, finalAction: finalActionInternal }
        );
      }
      if (thenAction) {
        thenAction({ fileID, response, url });
      }
    })
    .catch((error) => {
      if (catchAction) {
        catchAction({ error, fileID, url });
      }
      addNotification("Error uploading file.", "", NotificationType.error);
      console.log("ERROR");
      console.log(error);
      console.error(error);
    })
    .finally(() => {
      setURLInputSubmitPressed(false);
    });
};
