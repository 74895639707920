// DOCUMENTAI.JS IS TO BE DEPRECATED!

import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useFetchSavedPrompts } from "../../hooks/ragQuery/useFetchSavedPrompts";
import { handleAddToSavedPrompts } from "../../hooks/addToSavePrompts";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux"
import Typed from "react-typed";
import { Worker } from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import InvoiceDetails from "../crossReference/InvoiceDetails";
import TableDetails from "../../utils/TableDetails";
import {
  secureFilename,
  handleCSVDownload,
  handleXlsxDownload,
} from "../../utils";
import { v4 as uuidv4 } from "uuid";
import { useUser } from "@clerk/clerk-react";
import { logEvent } from "../../redux/reducers/app.reducer";
import { useQueryType } from "../../hooks/useQueryType";
import {
  NotificationType,
  useNotifications,
} from "../../utils/notifications/Notifications";
import DownloadDropdown from "../../utils/DownloadDropdown";
import FileUpload from "../../utils/FileUpload/FileUpload";
import FileTypeButton from "../../utils/FileTypeButton";
import SourcesTable from "../../utils/SourcesTable";
import { Link } from "react-router-dom";
import {
  ssePostRequest,
  postRequest,
  postRequestFormData,
} from "../../utils/httpUtils";
import { useAppInstanceData } from "../../hooks/useAppInstanceData";
import getChunks from "../../utils/GetChunks";
import RagQueryComponent from "../../utils/RagQueryComponent";
import { highlightUtil } from "../../utils/highlightUtil";
import { Viewer } from "../../utils";
import { handleURLSubmit } from "../../utils/handleURLSubmit";
import { customDocsSelector, getCustomDocs } from "../../redux/reducers/app.reducer";
import DeleteAppButton from "../../utils/DeleteAppButton";

const docTypes = ["Miscellaneous PDF Analyzer", "Invoice", "All Tables"];

const DocumentAI = (props) => {
  const dispatch = useDispatch()
  const { isSignedIn, user, isLoaded } = useUser();

  const { appInstanceData } = useAppInstanceData();
  const [filesDropped, setFilesDropped] = useState(false);
  const [fileIDs, setFileIDs] = useState([]); // I think we can kill this variable
  const [successfulFileUploads, setSuccessfulFileUploads] = useState([]);
  const [currentPDFURL, setCurrentPDFURL] = useState(null); // We can soon get rid of currentPDFURL as it's part of currentSource
  const [currentSource, setCurrentSource] = useState(null);
  const [fileType, setFileType] = useState("");
  const [urlInputSubmitPressed, setURLInputSubmitPressed] = useState(false);
  const [responseSource, setResponseSource] = useState(null);
  const [accountingQueryOutput, setAccountingQueryOutput] = useState(null);
  const [accountingQuerySubmitPressed, setAccountingQuerySubmitPressed] =
    useState(false);
  const [accountingQueryDone, setAccountingQueryDone] = useState(false); // Not used but may be
  const [addDocModalOpen, setAddDocModalOpen] = useState(false);
  const [addMoreFilesModalOpen, setAddMoreFilesModalOpen] = useState(false);
  const [savedPrompts, setSavedPrompts] = useState([]);
  const [doneAddToSavedPrompts, setDoneAddToSavedPrompts] = useState(false);
  const [queryValues, setQueryValues] = useState([]); // TODO: Rename this state variable to checklistQuestions as queryValues is ambiguous
  const [customDocTypes, setCustomDocTypes] = useState([]);
  const [highlightAreas, setHighlightAreas] = useState([]);
  const [chunkLocations, setChunkLocations] = useState([]);
  const [pdfDetails, setPDFDetails] = useState(null);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [tables, setTables] = useState([]);
  const [filesUploading, setFilesUploading] = useState([]);

  const [currentInvoiceDetailsIndex, setCurrentInvoiceDetailsIndex] =
    useState(0);

  const urlInput = useRef(null);
  const queryInput = useRef(null);
  const newDocName = useRef(null);
  const cancelButtonRef = useRef(null);
  const moreFilesCancelButtonRef = useRef(null);

  const [queryType, setQueryType, queryTypeError, queryTypes, queryHeaderText] =
    useQueryType();
  const { addNotification } = useNotifications();
  const customDocs = useSelector(customDocsSelector)

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      dispatch(logEvent({
        user,
        event: `Looked at DocumentAI.`
      }));
    }
  }, [isLoaded, isSignedIn, user]);

  const shouldSkipResetAnalyzerState = useRef(false);

  const resetAnalyzerState = useCallback(() => {
    if (shouldSkipResetAnalyzerState.current) {
      shouldSkipResetAnalyzerState.current = false;
      return;
    }
    setAccountingQueryOutput([]);
    setAccountingQueryDone(false);
    setHighlightAreas([]);
    setChunkLocations([]);

    if (queryInput && queryInput.current !== null) {
      queryInput.current.value = "";
    }
  }, [
    queryInput,
    setAccountingQueryOutput,
    setAccountingQueryDone,
    setHighlightAreas,
    setChunkLocations,
    shouldSkipResetAnalyzerState,
  ]);

  const removeFile = ({fileID}) => {
    setFileIDs(fileIDs => fileIDs.filter((id) => id !== fileID));
    setSuccessfulFileUploads(successfulFileUploads => {
      return successfulFileUploads.filter((file) => file.data["file_id"] !== fileID)
    });
  };

  // Gets any custom docs from the backend and creates buttons
  useEffect(() => {
    if (user) {
      try {
        dispatch(getCustomDocs({ user }))
      } catch(e) {
        addNotification(
          "Error getting your saved custom documents.", "", NotificationType.error
        );
      }
    }
  }, [user]);

  useEffect(() => {
    if (customDocs) setCustomDocTypes(customDocs)
  }, [customDocs]);

  // Gets saved prompts for the particular button

  useFetchSavedPrompts({
    user,
    queryType,
    setSavedPrompts,
    addNotification,
    docType: fileType,
    pdfDetails,
  });

  // This runs once the file has been uploaded and creates our RAG embeddings if NOT an invoice. If an invoice, this uses Azure Document Intelligence to get the key-value pairs. And if it's a webpage, it's dealt with accordingly though using the same fileType variable which isn't good.
  // TODO: Eventually put parseFile in here.
  const parsePDF = async (fileID, fileType) => {
    console.log("Following is the fileType");
    console.log(fileType);

    if (
      fileType === "webpage" // Annoyingly, this fileType is not really the same category as the fileType below of invoices etc. We need to work on that and separate this out into multiple files. This is super messy.
    ) {
      try {
        const { data } = await postRequest("/api/parse_webpage", {
          filetype: fileType,
          file_id: fileID,
        });
        setPDFDetails(data);
        addNotification(
          "Webpage successfully parsed.",
          "Text from the webpage has been split and vectorized.",
          NotificationType.success
        );
        console.log(data);
      } catch (error) {
        addNotification("Error parsing webpage.", "", NotificationType.error);
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    }

    if (
      fileType !== "Invoice" &&
      fileType !== "webpage" &&
      !fileType.includes("Table") &&
      !fileType.includes("Transactions")
    ) {
      try {
        const { data } = await postRequest("/api/parse_file", {
          document_type: fileType,
          file: fileID,
        });
        setPDFDetails(data);
        addNotification(
          "File successfully parsed.",
          "Text from the file has been split and vectorized.",
          NotificationType.success
        );
      } catch (error) {
        addNotification("Error parsing file.", "", NotificationType.error);
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    }

    if (fileType === "Invoice") {
      try {
        const { data } = await postRequest("/api/analyze_invoice", {
          file: fileID,
        });

        setInvoiceDetails((currentInvoices) => [...currentInvoices, data]);
        console.log(data);
        addNotification(
          "Invoice successfully parsed.",
          "",
          NotificationType.success
        );
      } catch (error) {
        addNotification("Error parsing invoice.", "", NotificationType.error);
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    }

    if (fileType === "All Tables" || fileType === "1099B Transactions") {
      let specificFormat =
        fileType === "1099B Transactions" ? "1099b_transactions" : null;

      console.log("We should be getting the tables now");
      try {
        ssePostRequest(
          "/api/get_tables",
          {
            file: fileID,
            specific_format: specificFormat,
          },
          {
            onStatus: (jsonPayload) => {
              console.log("Status:", jsonPayload);
            },
            onFinal: (jsonPayload) => {
              console.log("Final:", jsonPayload);
              // Process the final response data
              setTables(jsonPayload.value);
              addNotification(
                "Tables successfully parsed.",
                "",
                NotificationType.success
              );
            },
            onError: (error) => {
              console.error("Error:", error);
              addNotification(
                "Error parsing statement.",
                "",
                NotificationType.error
              );
            },
          }
        );
        // You may want to store the source for later use, similar to how it's done in useAccountingQueryRequest
        // setResponseSource(source);
      } catch (error) {
        addNotification(
          "Error submitting your query.",
          "",
          NotificationType.error
        );
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleAccountingQuerySubmit = async (event, queryType) => {
    setAccountingQuerySubmitPressed(true);
    setDoneAddToSavedPrompts(false);

    setAccountingQueryOutput("");
    setAccountingQueryDone(false);

    setHighlightAreas([]);
    setChunkLocations([]);

    if (responseSource) {
      console.log("\tClosing SSE connection");
      responseSource.removeEventListener("message");
      responseSource.close();
    }

    event.preventDefault();

    const formData = new FormData(event.target),
      formDataObj = Object.fromEntries(formData.entries());

    // USED FOR MISC PDF ANALYZER
      let full_query = formDataObj.query;
      let system = "You're given text from a PDF.";

      if (Object.keys(appInstanceData).length != 0) {
        full_query = `${appInstanceData.customizations.user}\n\nQuery: ${formDataObj.query}`;
        system = system + " " + appInstanceData.customizations.system;
      }

      console.log("SYSTEM");
      console.log(system);
      console.log("FULL QUERY!");
      console.log(full_query);

      const payload_for_query = {
        system: system,
        query: full_query,
        streaming: true,
        files: fileIDs,
        user: user,
        query_type: queryType,
        get_chunks_only: false,
        file_type: fileType,
        app_instance_id: appInstanceData.id,
      };

      const source = ssePostRequest("/api/query_chatbot", payload_for_query, {
        onStatus: (jsonPayload) => {
          console.log("Status update from backend.");
          console.log(jsonPayload["value"]);
        },
        onStreamingText: (jsonPayload) => {
          console.log("Streaming text from backend.");
          setAccountingQueryOutput((current) => current + jsonPayload["value"]);
        },
        onFinal: async (jsonPayload) => {
          console.log("BOARD MEETING DOCUMENT ANALYZED");
          console.log(jsonPayload["value"]);
          await getChunks({
            payload: payload_for_query,
            setHighlightAreas,
            setChunkLocations
        });
          setAccountingQueryDone(true);
        },
      });

      setResponseSource(source);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setAccountingQueryOutput(null);
      setAccountingQuerySubmitPressed(false);
      setAccountingQueryDone(false);
      setPDFDetails(null);
      setAddMoreFilesModalOpen(false);
      setFilesDropped(true);

      if (responseSource) {
        responseSource.removeEventListener("message");
        responseSource.close();
      }

      acceptedFiles.forEach((file) => {
        const data = new FormData();
        const fileID = secureFilename(uuidv4());

        data.append("file", file);
        data.append("fileID", fileID);
        data.append("user", JSON.stringify({ id: user.id }));

        setFilesUploading((previousFiles) => [...previousFiles, file]);
        postRequestFormData("/api/upload", data)
          .then((response) => {
            setFilesUploading((previousFiles) =>
              previousFiles.filter((f) => f !== file)
            );
            setFileIDs([fileID]);
            setSuccessfulFileUploads((previousUploads) => [
              ...previousUploads,
              response,
            ]);
            setURLInputSubmitPressed(false);
            parsePDF(fileID, fileType);
            addNotification(
              "File uploaded.",
              `${file.name} was correctly uploaded`,
              NotificationType.success
            );
          })
          .catch((error) => {
            addNotification(
              "Error uploading file.",
              "",
              NotificationType.error
            );
            setFileIDs((fileIDs) => fileIDs.filter((id) => id !== fileID));
            setSuccessfulFileUploads((successfulFileUploads) => {
              return successfulFileUploads.filter(
                (file) => file.data["file_id"] !== fileID
              );
            });
            console.error(error);
          });
      });
    },
    [fileIDs, fileType, user]
  );

  useEffect(() => {
    if (successfulFileUploads.length > 0) {
      console.log("SUCCESSFUL FILE UPLOADS!");
      console.log(successfulFileUploads);
      setFileIDs(successfulFileUploads.map((file) => file.data["file_id"]));
      setCurrentPDFURL(successfulFileUploads[0].data["url"]); // TODO: THis doesn't need to be set again and again, but it's okay for now.
      setCurrentSource(successfulFileUploads[0]);
    } else {
      setCurrentPDFURL(null);
      setCurrentSource(null);
      setAccountingQueryOutput(null);
      setAccountingQuerySubmitPressed(false);
      setAccountingQueryDone(false);
      setPDFDetails(null);
      setFileIDs([]);
      setFilesDropped(false);
    }
  }, [successfulFileUploads]);

  // Users can add new documents.
  const handleNewDocSubmit = async (event) => {
    event.preventDefault();

    if (newDocName.current.value !== "") {
      if (isLoaded && isSignedIn) {
        dispatch(logEvent({
          user,
          event: `Added a custom document type to DocumentAI: ${newDocName.current.value}`
        }));
      }

      const newDocNameAsText = newDocName.current.value;

      setCustomDocTypes((prevDocTypes) => [...prevDocTypes, newDocNameAsText]);

      setAddDocModalOpen(false);

      postRequest(`/api/documentai_newdoc`, {
        newDocNameAsText: newDocNameAsText,
        user: user,
      })
        .then((response) => {
          addNotification(
            "Custom document added",
            `${newDocNameAsText} added to your custom document types.`,
            NotificationType.success
          );
          console.log(response);
        })
        .catch((error) => {
          addNotification(
            "Error adding your custom document type.",
            "",
            NotificationType.error
          );
          console.error(error);
        });
    }
  };

  // When the stream is done, user has the option to save this prompt as a default. This handles that button click.
  const handleAddToSavedPromptsButton = (event) =>
    handleAddToSavedPrompts({
      event,
      queryType,
      queryInput,
      queryValues,
      savedPrompts,
      setSavedPrompts,
      setDoneAddToSavedPrompts,
      user,
      fileType,
      addNotification,
    });

  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  // https://react-dropzone.org/#!/Accepting%20specific%20file%20types

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  // TODO: It'd be great to set focus on this field when the modal is opened, but this doesn't work.
  // useEffect(() => {
  //   if (addDocModalOpen) {
  //     newDocName.current.focus();
  //   }
  // }, [addDocModalOpen]);

  // https://react-pdf-viewer.dev/examples/highlight-multiple-keywords-initially/
  // const searchPluginInstance = searchPlugin({
  //   keyword: ["FORM 10-K"],
  // });
  // const { ShowSearchPopoverButton } = searchPluginInstance;

  const highlightPluginInstance = highlightUtil(
    highlightAreas,
    currentSource?.data?.url
  );

  // Buttons up top with different file types
  const renderFileTypeButton = (label) => (
    <FileTypeButton
      key={label}
      label={label}
      fileType={fileType}
      setFileType={setFileType}
      setFileIDs={setFileIDs}
      setSavedPrompts={setSavedPrompts}
      setDoneAddToSavedPrompts={setDoneAddToSavedPrompts}
      setSuccessfulFileUploads={setSuccessfulFileUploads}
      setFilesDropped={setFilesDropped}
      setHighlightAreas={setHighlightAreas}
      setChunkLocations={setChunkLocations}
      setInvoiceDetails={setInvoiceDetails}
      setTables={setTables}
    />
  );

  useEffect(() => {
    if ("fileType" in props) {
      //TODO: HERE WE NEED TO RESET EVERYTHING LIKE setTables([]) etc.
      setFileType(props.fileType);
    }
  }, [props.fileType]);

  const mappedInvoiceDetails = useMemo(() => {
    if (!invoiceDetails || !successfulFileUploads) {
      return [];
    }
    return invoiceDetails.map((invoice, index) => {
      const invoiceAsJSON = JSON.parse(invoice.data);
      return {
        Filename: successfulFileUploads[index].data["filename"],
        Date: invoiceAsJSON["Invoice Date"]?.text || "N/A",
        Vendor: invoiceAsJSON["Vendor Name"]?.text || "N/A",
        Customer: invoiceAsJSON["Customer Name"]?.text || "N/A",
        Amount: invoiceAsJSON["Invoice Total"]?.text?.amount || "N/A",
        uuid: invoice.uuid,
      };
    });
  }, [invoiceDetails, successfulFileUploads]);

  const handleCSVDownloadCallback = useCallback(() => {
    handleCSVDownload(mappedInvoiceDetails);
  }, [mappedInvoiceDetails]);

  const handleXlsxDownloadCallback = useCallback(() => {
    handleXlsxDownload(mappedInvoiceDetails);
  }, [mappedInvoiceDetails]);

  const analyzerContext = {
    accountingQueryDone: accountingQueryDone,
    accountingQueryOutput: accountingQueryOutput,
    accountingQuerySubmitPressed: accountingQuerySubmitPressed,
    currentReportURL: currentPDFURL,
    currentSource,
    doneAddToSavedPrompts: doneAddToSavedPrompts,
    handleAccountingQuerySubmit: handleAccountingQuerySubmit,
    handleAddToSavedPromptsButton: handleAddToSavedPromptsButton,
    boardMinuteDetails: [{ summary: "" }],
    queryHeaderText: queryHeaderText,
    queryInput: queryInput,
    queryType: queryType,
    queryTypes: queryTypes,
    queryValues,
    resetAnalyzerState: resetAnalyzerState,
    responseSource: responseSource,
    savedPrompts: savedPrompts,
    setCurrentPDFURL: setCurrentPDFURL,
    setCurrentSource,
    setQueryType: setQueryType,
    setQueryValues: setQueryValues,
    setSavedPrompts: setSavedPrompts,
    successfulFileUploads: successfulFileUploads,
    chunkLocations: chunkLocations,
  };

  const preActionForHandleURLSubmit = () => {
    setURLInputSubmitPressed(true);

    setAccountingQueryOutput(null);
    setAccountingQuerySubmitPressed(false);
    setAccountingQueryDone(false);
    setPDFDetails(null);
    setAddMoreFilesModalOpen(false);
    setFilesDropped(true);

    if (responseSource) {
      responseSource.removeEventListener("message");
      responseSource.close();
    }
  };

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <Transition.Root show={addDocModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setAddDocModalOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <form onSubmit={handleNewDocSubmit}>
                    <div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Add a custom document type
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Add a custom document type, to which you can add
                            saved prompts and extract key information.
                          </p>

                          <br />

                          <div className="flex gap-2 w-full">
                            <input
                              type="text"
                              ref={newDocName}
                              className="shadow appearance-none border rounded w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-none"
                              placeholder="Document type name"
                            ></input>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="submit"
                        className="
                        text-white font-bold py-3 px-4 rounded text-center bg-gray-600 hover:bg-customHighlightColor

                        inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        // onClick={() => setAddDocModalOpen(false)}
                      >
                        Add Custom Document
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={() => setAddDocModalOpen(false)}
                        data-testid="cancel-button"
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={addMoreFilesModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={moreFilesCancelButtonRef}
          onClose={setAddMoreFilesModalOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <FileUpload
                    onDrop={onDrop}
                    handleURLSubmit={(event) =>
                      handleURLSubmit(event, {
                        urlInput,
                        uuidv4,
                        secureFilename,
                        postRequest,
                        user,
                        setFileIDs,
                        parseFile: parsePDF,
                        setSuccessfulFileUploads,
                        setURLInputSubmitPressed,
                        addNotification,
                        NotificationType,
                        setPDFDetails,
                        preAction: preActionForHandleURLSubmit,
                        catchAction: removeFile,
                      })
                    }
                    urlInput={urlInput}
                  />
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setAddMoreFilesModalOpen(false)}
                    ref={moreFilesCancelButtonRef}
                  >
                    Cancel
                  </button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="m-5">
        <h1 className="text-2xl font-bold mb-5">
          <div className="flex justify-between items-center w-full">
            {Object.keys(appInstanceData).length === 0
              ? "fileType" in props
                ? props.fileType
                : "DocumentAI"
              : appInstanceData.custom_name}
            <div className="flex">
              <Link
                to={`/app/edit/${appInstanceData.id}`}
                className="flex items-center pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
              >
                Edit
              </Link>
              <DeleteAppButton app_instance_id={appInstanceData.id} />
            </div>
          </div>
        </h1>

        {/* Girish: fileType should always be in props so we'll never get here. Which means we can ditch a lot of code! */}
        {!("fileType" in props) && (
          <>
            <div className="flex flex-col justify-center items-center space-y-4">
              <div className="flex space-x-4">
                {docTypes.map(renderFileTypeButton)}
                {/* <SignedIn> */}
                <button
                  className="text-gray-500 hover:text-customHighlightColor hover:font-bold bg-white hover:bg-purple-100 border border-gray-300 rounded-full shadow-sm hover:shadow-md transition duration-150 ease-in-out w-10 h-8 flex items-center justify-center"
                  aria-label="Add custom document type"
                  onClick={() => setAddDocModalOpen(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 4v16m8-8H4"
                    />
                  </svg>
                </button>
                {/* </SignedIn> */}
              </div>
              <div className="flex space-x-4">
                {customDocTypes && customDocTypes.map(renderFileTypeButton)}
              </div>
            </div>
            <br />
          </>
        )}

        {fileType !== "" &&
          fileIDs.length == 0 &&
          !urlInputSubmitPressed &&
          !filesDropped && (
            <FileUpload
              onDrop={onDrop}
              handleURLSubmit={(event) =>
                handleURLSubmit(event, {
                  urlInput,
                  uuidv4,
                  secureFilename,
                  postRequest,
                  user,
                  setFileIDs,
                  parseFile: parsePDF,
                  setSuccessfulFileUploads,
                  setURLInputSubmitPressed,
                  addNotification,
                  NotificationType,
                  setPDFDetails,
                  preAction: preActionForHandleURLSubmit,
                  catchAction: removeFile,
                })
              }
              urlInput={urlInput}
            />
          )}

        <div className="mt-2">
          {filesDropped && (
            <>
              {/* {fileIDs.length > 0 && ( //THIS ABOVE IF NECESSARY */}
              {/* {successfulFileUploads.map((fileUpload, index) => ( */}
              <p>
                <SourcesTable
                  successfulFileUploads={successfulFileUploads}
                  currentPDFURL={currentPDFURL}
                  setCurrentPDFURL={setCurrentPDFURL}
                  currentSource={currentSource}
                  setCurrentSource={setCurrentSource}
                  fileType={fileType}
                  setCurrentInvoiceDetailsIndex={setCurrentInvoiceDetailsIndex}
                  invoiceDetails={invoiceDetails}
                  setSuccessfulFileUploads={setSuccessfulFileUploads}
                  setInvoiceDetails={setInvoiceDetails}
                  shouldSkipResetAnalyzerState={shouldSkipResetAnalyzerState}
                  urlInput={urlInput}
                  urlInputSubmitPressed={urlInputSubmitPressed}
                />

                {fileType !== "Board Minutes Analyzer" &&
                  fileType !== "Inspection Report Analyzer" &&
                  fileType !== "Contract Analyzer" &&
                  (fileType.includes("Table") ||
                    fileType === "Miscellaneous PDF Analyzer") && (
                    <button
                      className="text-gray-500 text-sm hover:text-customHighlightColor bg-white"
                      onClick={() => setAddMoreFilesModalOpen(true)}
                    >
                      + Add more{" "}
                      {fileType != "Invoice" ? "sources" : "invoices"}
                    </button>
                  )}
                {fileType === "Invoice" && (
                  <>
                    <br />
                    <DownloadDropdown
                      handleCSVDownloadCallback={handleCSVDownloadCallback}
                      handleXlsxDownloadCallback={handleXlsxDownloadCallback}
                    />
                  </>
                )}
              </p>
              {filesUploading.length > 0 && (
                <p className="text-sm">
                  Uploading <Typed strings={["..."]} loop typeSpeed={40} />
                </p>
              )}
              {fileIDs.length > 0 &&
                !pdfDetails &&
                invoiceDetails.length === 0 &&
                Object.keys(tables ?? {}).length === 0 && (
                  <p className="text-sm">
                    Parsing source{fileIDs.length > 1 ? "s" : null}
                    <Typed strings={["..."]} loop typeSpeed={40} />
                  </p>
                )}

              {(pdfDetails ||
                invoiceDetails?.length > 0 ||
                Object.keys(tables ?? {}).length > 0) && ( // EDIT THIS
                <div className="flex h-screen">
                  <div
                    className="flex-1 p-4 h-screen"
                    // style={{
                    //   border: "1px solid rgba(0, 0, 0, 0.3)",
                    //   height: "750px",
                    // }}
                  >
                    {/* <ShowSearchPopoverButton>
                        {(props) => (
                          <button
                            style={{
                              backgroundColor: "#357edd",
                              border: "none",
                              borderRadius: "4px",
                              color: "#ffffff",
                              cursor: "pointer",
                              padding: "8px",
                            }}
                            onClick={props.onClick}
                          >
                            Search
                          </button>
                        )}
                      </ShowSearchPopoverButton> */}
                    {currentSource && (
                      <Viewer
                        fileUrl={currentSource.data["url"]}
                        plugins={[
                          highlightPluginInstance,
                          pageNavigationPluginInstance,
                        ]}
                      />
                    )}
                  </div>
                  <div className="overflow-y-scroll h-screen w-1/2">
                    {invoiceDetails.length > 0 && (
                      <InvoiceDetails
                        invoiceDetails={
                          invoiceDetails[currentInvoiceDetailsIndex]
                        }
                        setHighlightAreas={setHighlightAreas}
                      />
                    )}
                    {
                      Object.keys(tables ?? {}).length > 0 &&
                        tables["tables"].map((table) => (
                          <>
                            <TableDetails
                              tableDetails={table.cells}
                              setHighlightAreas={setHighlightAreas}
                              jumpToPage={jumpToPage}
                            />
                            <br />
                          </>
                        ))
                      // <pre>{JSON.stringify(tables[0], null, 2)}</pre>
                    }
                    {pdfDetails &&
                      !fileType.includes("Transactions") &&
                      !fileType.includes("Table") && (
                        <div className="flex-1 p-4 space-x-2">
                          <RagQueryComponent
                            analyzerContext={analyzerContext}
                            fileIDs={fileIDs}
                            jumpToPage={jumpToPage}
                            userObj={{ id: user.id }}
                            appInstanceId={appInstanceData.id}
                            originalUserText={queryInput?.current?.value}
                            generatedResponse={accountingQueryOutput}
                            setAccountingQueryDone={setAccountingQueryDone}
                          />
                        </div>
                      )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Worker>
  );
};

export default DocumentAI;
