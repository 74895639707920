import React, { Fragment } from "react";
import { Dialog as DialogInternal, Transition } from '@headlessui/react'

/**
 * A reusable dialog component that displays content within a modal dialog.
 * It uses the Headless UI library for accessibility and styling.
 *
 * Props:
 * - children: The content to display inside the dialog.
 * - isOpen: A boolean indicating whether the dialog should be shown.
 * - closeDialog: A function to close the dialog.
 * - title: The title of the dialog displayed at the top.
 * - description: An optional description below the title.
 */
const Dialog = ({ children, isOpen, closeDialog, title, description }) => {
    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <DialogInternal open={isOpen} onClose={closeDialog} as="div" className="relative z-10">
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            {/* This is an overlay */}
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >

                            <DialogInternal.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <DialogInternal.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">{title}</DialogInternal.Title>
                                {description && <DialogInternal.Description>{description}</DialogInternal.Description>}
                                {children}
                            </DialogInternal.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </DialogInternal>
        </Transition.Root>
    )
}

export default Dialog;