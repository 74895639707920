import React, { useCallback, useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid"
import cn from "classnames"

const Table = ({ colNames, rows, onClickRow, itemsPerPage = 1000 }) => {
  const [page, setPage] = useState(1);
  const [filteredRows, setFilteredRows] = useState();
  const [pages, setPages] = useState([])

  const next = () => setPage(page + 1);
  const prev = () => setPage(page - 1);

  useEffect(() => {
    generateFilteredRows();
    if (rows && rows.length) {
      const _pages = []
      for(let i = 1;i <= Math.ceil(rows.length / itemsPerPage); i++) {
        _pages.push(i)
      }
      setPages(_pages)
    }
  }, [rows]);

  useEffect(() => {
    generateFilteredRows();
  }, [page]);

  const generateFilteredRows = useCallback(() => {
    if (rows) {
      let _filteredRows = [];
      if (page === 1) {
        _filteredRows = rows.slice(0, itemsPerPage);
      } else {
        _filteredRows = rows.slice(
          (page - 1) * itemsPerPage,
          itemsPerPage * page
        );
      }
      setFilteredRows(_filteredRows);
    }
  }, [page, rows]);

  const [clickedRow, setClickedRow] = useState(null);

  const getBgColor = (colorName) => {
    switch(colorName) {
      case "green":
        return "#eefff0"
      case "violet":
        return "#f5f4fc"
      default:
        return "#ffffff"
    }
  }

  const getBorderColor = (colorName) => {
    switch(colorName) {
      case "green":
        return "#238400"
      case "violet":
        return "#5f00f7"
      default:
        return "#e7e7e7"
    }
  }
  
  return (
    <>
      <table className="table-auto bg-white shadow-lg rounded-md w-full">
        {colNames && colNames.length > 0 && (
          <thead>
            <tr className="border-b">
              {colNames &&
                colNames.map((c, key) => (
                  <th
                    key={key}
                    className="px-6 py-3 text-left text font-bold text-gray-700 tracking-wider"
                  >
                    {c}
                  </th>
                ))}
            </tr>
          </thead>
        )}
        {filteredRows && filteredRows.length > 0 && (
          <tbody className="bg-white">
            {filteredRows.map((r, key) => (
              <tr
                key={key}
                style={{
                  backgroundColor:
                    clickedRow === key ? getBgColor("green") : getBgColor(r?.metadata?.bgcolor),
                  borderBottomColor:
                    clickedRow === key ? getBorderColor("green") : getBorderColor(r?.metadata?.bgcolor),
                }}
                className="border-b-2"
                onClick={() => {
                  onClickRow(r, key);
                }}
              >
                {r?.row_data &&
                  r?.row_data.map((d, keyD) => (
                    <td key={keyD} className={cn("px-6 py-4 text-sm text-gray-500 font-size", { "font-bold": keyD === 0 })}>
                      {d}
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>

      {rows && rows.length > 0 && (
        <div className="flex justify-end items-center mt-4">
          {/* <span className="text-sm text-customDarkGray">
            {page}/{Math.ceil(rows.length / itemsPerPage)}
          </span> */}



          <div className="flex bg-white border border-gray-200 rounded-md">
            <button
              className="text-sm text-blue-500 p-2"
              disabled={page === 1}
              onClick={prev}
            >
              <ChevronLeftIcon className="w-4 h-4 text-gray-500 cursor-pointer" />
            </button>


            { pages.map((_page) => (
              <button
                className={cn(
                  "text-sm border-l border-r py-1 px-2",
                  {
                    "bg-purple-200 border-customHighlightColor text-customHighlightColor border-t border-b": page === _page,
                    "text-gray-500": page !== _page

                  }
                )}
                disabled={page === _page}
                onClick={() => setPage(_page)}
              >
                {_page < 10 ? `0${_page}` : _page}
              </button>
            )) }


            <button
              className="text-sm text-blue-500 p-2"
              disabled={page === Math.ceil(rows.length / itemsPerPage)}
              onClick={next}
            >
              <ChevronRightIcon className="w-4 h-4 text-gray-500 cursor-pointer" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Table;
