import { useCallback, useState, useEffect } from "react";
import { getRequest } from "../../utils/httpUtils";

const useFetchFootnotes = () => {
    const [footnotes, setFootnotes] = useState([]);

    const fetchFootnotes = useCallback(async () => {
        const response = await getRequest('/api/footnotes/');
        setFootnotes(response.data);
    }, []);

    useEffect(() => {
        fetchFootnotes();
    }, []);

    return { footnotes, fetchFootnotes };
}

export default useFetchFootnotes;