import ProgressBar from "../../utils/progressBar";
import ChooseTemplate from "./ChooseTemplate";
import GenerateFootnote from "./GenerateFootnote";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChooseFootnote from "./ChooseFootnote";
import { filesByAppSelector } from "../../redux/reducers/apps.reducer";
import { useAppInstanceData } from "../../hooks/useAppInstanceData";
import { getFilesByAppId } from "../../redux/reducers/apps.reducer";
import GenericExtractor from "../GenericExtractor/GenericExtractor";

const FootnoteAI = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const dispatch = useDispatch();
  const appFiles = useSelector(filesByAppSelector);
  const { appInstanceData } = useAppInstanceData();

  const getStatus = (stepIndex, currentStep) => {
    if (stepIndex === currentStep) return "current";
    if (stepIndex < currentStep) return "completed";
    return "upcoming";
  };

  const stepNames = [
    "Upload data",
    "Suggested footnotes",
    "Choose template",
    "Generate footnote",
  ];

  const steps = stepNames.map((name, index) => ({
    name,
    status: getStatus(index, currentStep),
  }));

  // FROM UPLOAD TEMPLATES
  const [
    successfulFileUploadsFromUploadTemplates,
    setSuccessfulFileUploadsFromUploadTemplates,
  ] = useState([]); // This comes from the app instance data, and can be edited on the frontend with Edit App

  // FROM UPLOAD DATA
  const [
    successfulFileUploadsFromUploadData,
    setSuccessfulFileUploadsFromUploadData,
  ] = useState([]);
  const [filesDroppedFromUploadData, setFilesDroppedFromUploadData] = useState(false);

  // FROM CHOOSE TEMPLATE
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleStepClick = (stepIndex) => {
    setCurrentStep(stepIndex);
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  useEffect(() => {
    if (appInstanceData && appInstanceData.id) {
      dispatch(getFilesByAppId(appInstanceData.id));
    }
  }, [appInstanceData]);

  useEffect(() => {
    console.log(
      "XG successfulFileUploadsFromUploadTemplates",
      successfulFileUploadsFromUploadTemplates
    );
    if (appFiles)
      setSuccessfulFileUploadsFromUploadTemplates(
        appFiles.map((file) => ({ data: file }))
      );
  }, [appFiles]);

  return (
    <>
      <ProgressBar steps={steps} onStepClick={handleStepClick} />

      {successfulFileUploadsFromUploadTemplates.length === 0 && 
        successfulFileUploadsFromUploadData.length === 0 && (
        <b>No sample data uploaded. Please click Edit below.</b>
      )}

      {currentStep === 0 && (
        <GenericExtractor
          successfulFileUploads={successfulFileUploadsFromUploadData}
          setSuccessfulFileUploads={setSuccessfulFileUploadsFromUploadData}
          filesDropped={filesDroppedFromUploadData}
          setFilesDropped={setFilesDroppedFromUploadData}
          type="upload"
        />
      )}
      {currentStep === 1 && (
        <>
          <ChooseFootnote />
        </>
      )}
      {currentStep === 2 && (
        <ChooseTemplate
          templates={successfulFileUploadsFromUploadTemplates}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
        />
      )}
      {currentStep === 3 && (
        <GenerateFootnote
          successfulFileUploadsFromUploadData={
            successfulFileUploadsFromUploadData
          }
          selectedTemplate={selectedTemplate}
          appInstanceID={appInstanceData.id}
        />
      )}

      <div className="flex justify-between w-full px-4 pb-1">
        <div className="w-1/2 flex justify-start">
          {currentStep > 0 ? (
            <button
              onClick={prevStep}
              className="flex items-center py-2 px-3 font-normal rounded-1 border-customHighlightColor text-customLightGray bg-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
            >
              Previous
            </button>
          ) : (
            <div className="ml-2 pt-4 pb-4 px-6"></div>
          )}
        </div>

        <div className="w-1/2 flex justify-end">
          {currentStep < steps.length - 1 ? (
            <button
              onClick={nextStep}
              className="flex items-center py-2 px-3 font-normal rounded-1 border-customHighlightColor text-customLightGray bg-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
            >
              Next
            </button>
          ) : (
            <div className="ml-2 pt-4 pb-4 px-6"></div>
          )}
        </div>
      </div>
    </>
  );
};

export default FootnoteAI;
