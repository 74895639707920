import React from "react";
import {
  RedirectToSignIn,
} from "@clerk/clerk-react";

const SignIn = () => {
  return <RedirectToSignIn />;
};

export default SignIn;
