import React, { useState, useMemo, useCallback } from "react";
import { useAppInstanceData } from "../../hooks/useAppInstanceData";
import FootnotesTable from "./FootnotesTable";
import FilesTable from "./FilesTable";
import FileUpload from "../../utils/FileUpload/FileUpload";
import { handleFileUpload } from "../../utils/FileUpload/handleFileUpload";
import { useUser } from "@clerk/clerk-react";
import { NotificationType, useNotifications } from "../../utils/notifications/Notifications";
import Dialog from "../../utils/Dialog";
import useFetchFootnotes from "./useFetchFootnotes";
import useFetchFootnoteFiles from "./useFetchFootnoteFiles";
import { extractData } from "../../utils/extractData";
import useTabs from "../../hooks/useTabs";
import Tabs from "../../utils/Tabs";

const footnotesTabs = [
  { id: "footnotes", label: "Footnotes" },
  { id: "files", label: "Files" },
]

const FootnotesDatabase = () => {
  const { footnotes, fetchFootnotes } = useFetchFootnotes();
  const { footnoteFiles, fetchFootnoteFiles } = useFetchFootnoteFiles();
  const { appInstanceData } = useAppInstanceData();

  const [uploading, setUploading] = useState(false);
  const [parsing, setParsing] = useState(false);
  const [activeTab, handleTabChange] = useTabs(footnotesTabs, "footnotes");

  const user = useUser();
  const { addNotification } = useNotifications();

  const showFilesTab = activeTab === "files";
  const showFootnotesTab = activeTab === "footnotes";

  const onDrop = useCallback(
    async (acceptedFiles) => {
      await handleFileUpload(
        acceptedFiles,
        { user, addNotification },
        {
          preAction: () => {
            setUploading(true);
            setDialogOpen(false);
          },
          thenAction: ({ fileID }) => {
            setUploading(false);
            setParsing(true);

            try {
              extractData(fileID, {
                onStatus: (jsonPayload) => {
                  console.log("Status:", jsonPayload);
                },
                onFinal: (jsonPayload) => {
                  console.log("Final:", jsonPayload);
                  if ("error" in jsonPayload.value) {
                    addNotification(
                      "No data found.",
                      "",
                      NotificationType.error
                    );
                  } else {
                    // addNewElement(fileID, {
                    //   financialStatements:
                    //     jsonPayload.value["financial_statements"],
                    //   footnotes: jsonPayload.value["footnotes"],
                    // });
                    // selectElementByFileID(fileID);
                    addNotification(
                      "Document parsed.",
                      "",
                      NotificationType.success
                    );
                    setParsing(false);
                    fetchFootnotes();
                    fetchFootnoteFiles();
                  }
                },
                onError: (error) => {
                  console.error("Error:", error);
                  addNotification(
                    "Error parsing document.",
                    "",
                    NotificationType.error
                  );
                },
              });
            } catch (error) {
              addNotification(
                "Error submitting your query.",
                "",
                NotificationType.error
              );
              console.error("Error fetching data:", error);
            }
          },
        }
      );
    },
    [user, addNotification, fetchFootnotes, fetchFootnoteFiles]
  );

  const footnoteDataWithFiles = useMemo(() => {
    if (footnotes && footnotes.length > 0) {
      return footnotes.map((footnote) => {
        const pdfFile = footnoteFiles.find(
          (file) => file.file_id === footnote.pdf_file_id
        );
        const screenshotFile = footnoteFiles.find(
          (file) => file.file_id === footnote.screenshot_file_id
        );
        return {
          ...footnote,
          pdfFile,
          screenshotFile,
        };
      });
    } else return [];
  }, [footnotes, footnoteFiles]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = useCallback(() => setDialogOpen(true), []);
  const closeDialog = useCallback(() => setDialogOpen(false), []);

  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">
            {appInstanceData.custom_name || "Footnotes Visualization"}
          </h1>
        </div>
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 relative">
          <Tabs tabs={footnotesTabs} currentTab={activeTab} onClickTab={handleTabChange} />
        </div>
        {showFilesTab && (
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 relative">
            {footnoteFiles && footnoteFiles.length > 0 && (
              <>
                <h3>Files</h3>
                <FilesTable files={footnoteFiles} fetchFootnotes={fetchFootnotes} fetchFootnoteFiles={fetchFootnoteFiles} />
              </>
            )}

            <Dialog isOpen={dialogOpen} closeDialog={closeDialog}>
              <FileUpload onDrop={onDrop} />
            </Dialog>

            <button
              disabled={dialogOpen}
              onClick={openDialog}
              className="pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed disabled:border-gray-300 disabled:hover:bg-gray-300 disabled:hover:text-gray-500 disabled:transition-colors disabled:duration-300"
            >
              Upload File
            </button>
            {uploading && <p>Uploading...</p>}
            {parsing && <p>Parsing...</p>}
          </div>
        )}
        {showFootnotesTab && (
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 relative">
            {footnoteDataWithFiles && footnoteDataWithFiles.length > 0 && (
              <>
                <h3>Footnotes</h3>
                <FootnotesTable footnotes={footnoteDataWithFiles} />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FootnotesDatabase;
