// FileTypeButton.js
import React, { useCallback } from "react";

/**
 * `FileTypeButton` is a React component that renders a button for selecting a file type.
 * It is used to manage the state of the selected file type and associated data.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.label - The label to display on the button, which corresponds to the file type.
 * @param {string} props.fileType - The current selected file type.
 * @param {function} props.setFileType - The function to update the current selected file type.
 * @param {function} props.setFileIDs - The function to reset the file IDs when the file type changes.
 * @param {function} props.setFileTypes - The function to reset the file types when the file type changes.
 * @param {function} props.setSavedPrompts - The function to reset the saved prompts when the file type changes.
 * @param {function} props.setDoneAddToSavedPrompts - The function to reset the flag indicating whether prompts have been added to saved prompts.
 * @param {function} props.setSuccessfulFileUploads - The function to reset the successful file uploads when the file type changes.
 * @param {function} props.setFilesDropped - The function to reset the flag indicating whether files have been dropped.
 * @param {function} props.setHighlightAreas - The function to reset the highlight areas when the file type changes.
 * @param {function} props.setChunkLocations - The function to reset chunk locations when the file type changes.
 * @param {function} props.setInvoiceDetails - The function to reset the invoice details when the file type changes.
 * @param {function} props.setTables - The function to reset the tables when the file type changes.
 *
 * @example
 * // Usage in a parent component
 * const renderFileTypeButton = (label) => (
 *   <FileTypeButton
 *     key={label}
 *     label={label}
 *     fileType={fileType}
 *     setFileType={setFileType}
 *     setFileIDs={setFileIDs}
 *     setFileTypes={setFileTypes}
 *     setSavedPrompts={setSavedPrompts}
 *     setDoneAddToSavedPrompts={setDoneAddToSavedPrompts}
 *     setSuccessfulFileUploads={setSuccessfulFileUploads}
 *     setFilesDropped={setFilesDropped}
 *     setHighlightAreas={setHighlightAreas}
 *     setChunkLocations={setChunkLocations}
 *     setInvoiceDetails={setInvoiceDetails}
 *     setTables={setTables}
 *   />
 * );
 */
export const FileTypeButton = ({
  label,
  fileType,
  setFileType,
  setFileIDs,
  setFileTypes,
  setSavedPrompts,
  setDoneAddToSavedPrompts,
  setSuccessfulFileUploads,
  setFilesDropped,
  setHighlightAreas,
  setChunkLocations,
  setInvoiceDetails,
  setTables,
}) => {
  const handleClick = useCallback(() => {
    setFileIDs([]);
    if (setFileTypes)
      setFileTypes([]);
    setSavedPrompts([]);
    setDoneAddToSavedPrompts(false);
    setSuccessfulFileUploads([]);
    setFilesDropped(false);
    setHighlightAreas([]);
    setChunkLocations([]);
    setInvoiceDetails([]);
    setTables([]);

    if (fileType === label) {
      setFileType("");
    } else {
      setFileType(label);
      if (setFileTypes)
        setFileTypes([label]);
    }
  }, [
    label,
    fileType,
    setFileType,
    setFileIDs,
    setFileTypes,
    setSavedPrompts,
    setDoneAddToSavedPrompts,
    setSuccessfulFileUploads,
    setFilesDropped,
    setHighlightAreas,
    setChunkLocations,
    setInvoiceDetails,
    setTables,
  ]);

  // className is a string that dynamically sets the button's CSS classes based on the current file type.
  // It applies a custom purple background if the file type matches the button's label,
  // otherwise, it applies a gray background with a hover effect to change to custom purple.
  // If a file type is already selected, the button is disabled and its opacity is reduced.
  const className = `text-white font-bold py-3 px-4 rounded text-center
  ${
    fileType === label ? "bg-customHighlightColor" : "bg-gray-600 hover:bg-customHighlightColor"
  }  
  ${fileType && fileType !== label ? "opacity-50" : ""}
  `;

  return (
    <button
      onClick={handleClick}
      className={className}
      disabled={fileType && fileType === label}
    >
      {label}
    </button>
  );
};

export default FileTypeButton;
