import React from "react";
import AppsList from "./appsPage/AppsList";
import useAppList from "./appsPage/useAppList";

const MyApps = () => {
  const appData = useAppList();
  return (
    <AppsList pageName="My Apps" appsArray={appData && appData.my_apps} />
  );
};

export default MyApps;
