import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux"
import StreamingText from "../utils/StreamingText";
import QuestionCard from "../utils/QuestionCard";
import Typed from "react-typed";
import { useUser } from "@clerk/clerk-react";
import { ssePostRequest } from "../utils/httpUtils";
import { Link } from "react-router-dom";
import { useAppInstanceData } from "../hooks/useAppInstanceData";
import Feedback from "../utils/Feedback";
import { logEvent } from "../redux/reducers/app.reducer";
import DeleteAppButton from "../utils/DeleteAppButton";

const ChatGPTStyleChat = (props) => {
  const dispatch = useDispatch()
  const { isSignedIn, user, isLoaded } = useUser();
  const { appInstanceData } = useAppInstanceData();

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      dispatch(logEvent({
        user,
        event: `Opened ChatGPT-style chat.`
      }));
    }
  }, [isLoaded, isSignedIn, user]);

  const textAreaRef = useRef(null);

  useEffect(() => {
    resetEverything(true);
  }, [props]);

  const bodyRef = useRef(null);

  // Scrolls to bottom of page on stream update
  useEffect(() => {
    const div = bodyRef.current;
    div.scrollTop = div.scrollHeight;
  });

  const [handbookQuerySource, setHandbookQuerySource] = useState(null);
  const [handbookOutput, setHandbookOutput] = useState(null);
  const [handbookSubmitPressed, setHandbookSubmitPressed] = useState(false);
  const [handbookDone, setHandbookDone] = useState(false); // Not used but may be

  const handbook = props.handbook;
  const sampleQuestions = props.sampleQuestions;

  const [query, setQuery] = useState("");

  const fillAndSubmitQuestion = (sampleQuestion) => {
    setQuery(sampleQuestion);
    handleHandbookSubmit(sampleQuestion);
  };

  const resetEverything = (reallyEverything) => {
    setHandbookOutput("");
    setHandbookDone(false);

    // We don't want these being reset if, for example, the user has just hit the Submit button
    if (reallyEverything == true) {
      setQuery("");
      setHandbookSubmitPressed(false);
    }

    if (handbookQuerySource) {
      console.log("\tClosing SSE connection");
      handbookQuerySource.removeEventListener("message");
      handbookQuerySource.close();
    }

    textAreaRef.current.focus();
  };

  const handleHandbookSubmit = (inputQuery = null, event = null) => {
    if (event) {
      event.preventDefault();
    }

    const actualQuery = inputQuery || query;

    resetEverything(false);
    setHandbookSubmitPressed(true);

    let system = "You are a chatbot.";
    let user_query = "";

    if (
      Object.keys(appInstanceData).length !== 0 &&
      appInstanceData?.customizations
    ) {
      system = appInstanceData.customizations.system;
      user_query = appInstanceData.customizations.user;
    }

    const handbookPayload = {
      system: system,
      user_query: user_query,
      handbook: handbook,
      query: actualQuery,
      user: user,
      streaming: true,
    };

    const newSource = ssePostRequest(
      "/api/routed_guidelines_chat",
      handbookPayload,
      {
        onStreamingText: (jsonPayload) => {
          setHandbookOutput((output) => {
            return output + jsonPayload.value;
          });
        },
        onFinal: () => {
          setHandbookDone(true);
        },
        onError: (error) => {
          console.error("Invalid JSON:", error);
        },
      }
    );

    setHandbookQuerySource(newSource);
  };

  return (
    <form onSubmit={(e) => handleHandbookSubmit(null, e)}>
      <div
        className="flex flex-col h-screen"
        style={{ height: "calc(100vh - 100px)" }}
      >
        <div className="bg-white">
          <h1 className="text-2xl font-bold">
            {Object.keys(appInstanceData).length === 0 ? (
              props.title
            ) : (
                <div className="flex justify-between items-center w-full">
                  <span>{appInstanceData.custom_name}</span>
                  <div className="flex">
                    <Link
                      to={`/app/edit/${appInstanceData.id}`}
                      className="flex items-center pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
                    >
                      Edit
                    </Link>
                    <DeleteAppButton app_instance_id={appInstanceData.id} />
                  </div>
                </div>
              
            )}
          </h1>
          <p className="mb-5">
            {!appInstanceData?.customizations?.system ? (
              props.description
            ) : (
              <>
                System Prompt: <i>{appInstanceData?.customizations?.system}</i>
              </>
            )}
          </p>
          
        </div>

        <div className="flex-grow overflow-y-auto" ref={bodyRef}>
          {!handbookSubmitPressed && (
            <>
              <div className="mb-4 w-2/3 mx-auto">
                <div className="flex flex-wrap justify-center">
                  {sampleQuestions && sampleQuestions.length > 0
                    ? sampleQuestions.map((question, index) => (
                        <QuestionCard
                          key={index}
                          question={question}
                          fillAndSubmitQuestion={fillAndSubmitQuestion}
                        />
                      ))
                    : null}
                </div>
              </div>
            </>
          )}

          {/* <div className="mt-5"> */}
          {handbookSubmitPressed && (
            <p className="bg-gray-200 w-full p-4">
              {appInstanceData.customizations?.user && (
                <>
                  <i className="text-xs">
                    {appInstanceData.customizations?.user}
                  </i>
                  <br />
                  <br />
                </>
              )}
              {query}
            </p>
          )}
          {handbookOutput === "" && handbookSubmitPressed && (
            <p>
              Categorizing your question and getting answer
              <Typed strings={["..."]} loop typeSpeed={40} />
            </p>
          )}
          {handbookOutput !== null &&
            handbookSubmitPressed &&
            handbookOutput !== "" && (
              <>
                <p className="bg-gray-100 w-full p-4">
                  <StreamingText text={handbookOutput} done={handbookDone} />
                </p>
                {handbookDone && (
                  <Feedback
                    userObj={{ id: user.id }} // passing only required piece
                    appInstanceId={appInstanceData.id}
                    originalUserText={query}
                    generatedResponse={handbookOutput}
                  />
                )}
              </>
            )}
        </div>
        {/* </div> */}

        <div className="bg-white flex-shrink-0 flex">
          <div className="mb-4 flex-grow">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Enter your question, or pick a sample above.
            </label>
            <textarea
              ref={textAreaRef}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-none"
              rows={3}
              name="query"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            ></textarea>
          </div>
          <div className="flex flex-col justify-between ml-4 h-full pt-4 pb-4 space-y-2">
            <button
              type="button"
              onClick={() => {
                resetEverything(true);
              }}
              className="flex items-center pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
            >
              Reset
            </button>
            <button
              type="submit"
              className="flex items-center pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
              // onClick={handleHandbookSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChatGPTStyleChat;
