import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { useFetchSavedPrompts } from "../../hooks/ragQuery/useFetchSavedPrompts";
import { handleAddToSavedPrompts } from "../../hooks/addToSavePrompts";
import { Dialog, Transition } from "@headlessui/react";
import Typed from "react-typed";
import { Worker } from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { useDispatch } from "react-redux"
import {
  deepCopy,
  findLast,
  generateAccountQueryMessage,
  removeFirstMatchingElement,
  secureFilename,
} from "../../utils";
import { v4 as uuidv4 } from "uuid";
import { useUser } from "@clerk/clerk-react";
import { logEvent } from "../../redux/reducers/app.reducer";
import { useQueryType } from "../../hooks/useQueryType";
import {
  NotificationType,
  useNotifications,
} from "../../utils/notifications/Notifications";
import FileUpload from "../../utils/FileUpload/FileUpload";
import SourcesTable from "../../utils/SourcesTable";
import { Link } from "react-router-dom";
import { ssePostRequest, postRequest } from "../../utils/httpUtils";
import { useAppInstanceData } from "../../hooks/useAppInstanceData";
import getChunks from "../../utils/GetChunks";
import RagQueryComponent from "../../utils/NewRagQueryComponent";
import { Viewer, updateLastAssistantMessage } from "../../utils";
import { handleURLSubmit } from "../../utils/handleURLSubmit";
import { highlightUtil } from "../../utils/highlightUtil";
import { parseFile } from "../../utils/parseFile";
import { handleFileUpload } from "../../utils/FileUpload/handleFileUpload";
import useConversation from "../../hooks/useConversation";

const DocumentAnalyzer = (props) => {
  const dispatch = useDispatch();
  const { isSignedIn, user, isLoaded } = useUser();
  const { createConversation, conversationUuid, initializeConversation, conversationInitialized  } = useConversation();
  const { appInstanceData } = useAppInstanceData();
  const [filesDropped, setFilesDropped] = useState(false);
  const [fileIDs, setFileIDs] = useState([]); // I think we can kill this variable
  const [successfulFileUploads, setSuccessfulFileUploads] = useState([]);
  const [currentPDFURL, setCurrentPDFURL] = useState(null); // We can soon get rid of currentPDFURL as it's part of currentSource
  const [currentSource, setCurrentSource] = useState(null);
  const fileType = "Miscellaneous PDF Analyzer";
  const [urlInputSubmitPressed, setURLInputSubmitPressed] = useState(false);
  const [responseSource, setResponseSource] = useState(null);
  const [accountingQueryOutput, setAccountingQueryOutput] = useState([]);
  const [accountingQuerySubmitPressed, setAccountingQuerySubmitPressed] =
    useState(false);
  const [accountingQueryDone, setAccountingQueryDone] = useState(false); // Not used but may be
  const [addMoreFilesModalOpen, setAddMoreFilesModalOpen] = useState(false);
  const [savedPrompts, setSavedPrompts] = useState([]);
  const [doneAddToSavedPrompts, setDoneAddToSavedPrompts] = useState(false);
  const [queryValues, setQueryValues] = useState([]); // TODO: Rename this state variable to checklistQuestions as queryValues is ambiguous
  const [highlightAreas, setHighlightAreas] = useState([]);
  const [chunkLocations, setChunkLocations] = useState([]);
  const [relevantFiles, setRelevantFiles] = useState([]);
  const [pdfDetails, setPDFDetails] = useState(null);
  const [filesParsing, setFilesParsing] = useState([]);
  const [filesUploading, setFilesUploading] = useState([]);

  const urlInput = useRef(null);
  const queryInput = useRef(null);
  const moreFilesCancelButtonRef = useRef(null);

  const [queryType, setQueryType, queryTypeError, queryTypes, queryHeaderText] =
    useQueryType();
  const { addNotification } = useNotifications();

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      dispatch(
        logEvent({
          user,
          event: `Looked at DocumentAnalyzer.`,
        })
      );
    }
  }, [isLoaded, isSignedIn, user]);

  const shouldSkipResetAnalyzerState = useRef(false);

  const resetAnalyzerState = useCallback(() => {
    if (shouldSkipResetAnalyzerState.current) {
      shouldSkipResetAnalyzerState.current = false;
      return;
    }
    setAccountingQueryOutput([]);
    setAccountingQueryDone(false);
    setHighlightAreas([]);
    setChunkLocations([]);

    if (queryInput && queryInput.current !== null) {
      queryInput.current.value = "";
    }
  }, [
    queryInput,
    setAccountingQueryOutput,
    setAccountingQueryDone,
    setHighlightAreas,
    setChunkLocations,
    shouldSkipResetAnalyzerState,
  ]);

  // Gets saved prompts for the particular button

  const { fetchData: fetchSavedPrompts } = useFetchSavedPrompts({
    user,
    queryType,
    setSavedPrompts,
    addNotification,
    docType: fileType,
    pdfDetails,
  });

  const handleAccountingQuerySubmit = async (event, queryType) => {
    setAccountingQuerySubmitPressed(true);
    setDoneAddToSavedPrompts(false);

    setAccountingQueryDone(false);

    setHighlightAreas([]);
    setChunkLocations([]);

    if (responseSource) {
      console.log("\tClosing SSE connection");
      responseSource.removeEventListener("message");
      responseSource.close();
    }

    event.preventDefault();

    const formData = new FormData(event.target),
      formDataObj = Object.fromEntries(formData.entries());

    setAccountingQueryOutput((accountingQueryOutput) => {
      const newQueryUser = generateAccountQueryMessage(
        formDataObj.query,
        "user",
        true
      );
      const newQueryOutput = generateAccountQueryMessage(
        "",
        "assistant",
        false
      );
      return [...accountingQueryOutput, newQueryUser, newQueryOutput];
    });
    // USED FOR MISC PDF ANALYZER
      let full_query = formDataObj.query;
      let system = "You're given text from a PDF.";

      if (Object.keys(appInstanceData).length != 0) {
        full_query = `${appInstanceData.customizations.user}\n\nQuery: ${formDataObj.query}`;
        system = system + " " + appInstanceData.customizations.system;
      }

      console.log("SYSTEM");
      console.log(system);
      console.log("FULL QUERY!");
      console.log(full_query);

      const payload_for_query = {
        system: system,
        query: full_query,
        streaming: true,
        files: fileIDs,
        user: user,
        query_type: queryType,
        get_chunks_only: false,
        file_type: fileType,
        history: accountingQueryOutput.filter((message) => message.completed),
        app_instance_id: appInstanceData.id,
      };

      const source = ssePostRequest("/api/query_chatbot", payload_for_query, {
        onStatus: (jsonPayload) => {
          setAccountingQueryOutput((accountingQueryOutput) => {
            return updateLastAssistantMessage(accountingQueryOutput, (last) => {
              const oldStatuses = last.statuses || [];
              last.statuses = [...oldStatuses, jsonPayload["value"]];
            });
          });
        },
        onStreamingText: (jsonPayload) => {
          setAccountingQueryOutput((accountingQueryOutput) => {
            return updateLastAssistantMessage(accountingQueryOutput, (last) => {
              last.message += jsonPayload["value"];
            });
          });
        },
        onMetadata: (jsonPayload) => {
          setAccountingQueryOutput((accountingQueryOutput) => {
            return updateLastAssistantMessage(accountingQueryOutput, (last) => {
              last.metadata = jsonPayload.value.metadata;
            });
          });
        },
        onFinal: async (jsonPayload) => {
          await getChunks({
            payload: payload_for_query,
            setHighlightAreas,
            setChunkLocations,
            successfulFileUploads,
            setRelevantFiles,
        });
          setAccountingQueryDone(true);
          setAccountingQueryOutput((accountingQueryOutput) => {
            return updateLastAssistantMessage(accountingQueryOutput, (last) => {
              last.completed = true;
            });
          });
        },
      });

      setResponseSource(source);
  };

  const catchAction = ({ fileID }) => {
    setFileIDs((fileIDs) => fileIDs.filter((id) => id !== fileID));
    setSuccessfulFileUploads((successfulFileUploads) =>
      successfulFileUploads.filter((file) => file.data["file_id"] !== fileID)
    );
    setFilesParsing((previousFiles) =>
      previousFiles.filter((id) => id !== fileID)
    );
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      const preAction = () => {
        setAccountingQueryOutput([]);
        setAccountingQuerySubmitPressed(false);
        setAccountingQueryDone(false);
        setPDFDetails(null);
        setAddMoreFilesModalOpen(false);
        setFilesDropped(true);

        if (responseSource) {
          responseSource.removeEventListener("message");
          responseSource.close();
        }
      };

      const beforePostAction = ({ file }) => {
        setFilesUploading((previousFiles) => [...previousFiles, file]);
      };

      const finalAction = ({ fileID }) => {
        setFilesParsing((previousFiles) =>
          previousFiles.filter((id) => id !== fileID)
        );
      };

      const thenAction = ({ fileID, fileType, response, file }) => {
        setFilesUploading((previousFiles) =>
          previousFiles.filter((f) => f !== file)
        );
        setFilesParsing((previousFiles) => [...previousFiles, fileID]);
        setFileIDs([fileID]);
        setSuccessfulFileUploads((previousUploads) => [
          ...previousUploads,
          response,
        ]);
        setURLInputSubmitPressed(false);
        parseFile(
          fileID,
          fileType,
          postRequest,
          setPDFDetails,
          addNotification,
          NotificationType,
          { catchAction, finalAction }
        );
      };

      handleFileUpload(
        acceptedFiles,
        { addNotification, fileType, user },
        { preAction, thenAction, catchAction, beforePostAction }
      );
    },
    [fileIDs, fileType, user]
  );

  useEffect(() => {
    if (successfulFileUploads.length > 0) {
      console.log("SUCCESSFUL FILE UPLOADS!");
      console.log(successfulFileUploads);
      setFileIDs(successfulFileUploads.map((file) => file.data["file_id"]));
      setCurrentPDFURL(successfulFileUploads[0].data["url"]); // TODO: THis doesn't need to be set again and again, but it's okay for now.
      setCurrentSource(successfulFileUploads[0]);
    } else {
      setCurrentPDFURL(null);
      setCurrentSource(null);
      setAccountingQueryOutput([]);
      setAccountingQuerySubmitPressed(false);
      setAccountingQueryDone(false);
      setPDFDetails(null);
      setFileIDs([]);
      setFilesDropped(false);
    }
  }, [successfulFileUploads]);

  // When the stream is done, user has the option to save this prompt as a default. This handles that button click.
  const handleAddToSavedPromptsButton = (event) =>
    handleAddToSavedPrompts({
      event,
      queryType,
      queryInput,
      queryValues,
      savedPrompts,
      setSavedPrompts,
      setDoneAddToSavedPrompts,
      user,
      fileType,
      addNotification,
    });

  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  // https://react-dropzone.org/#!/Accepting%20specific%20file%20types

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  const analyzerContext = {
    accountingQueryDone: accountingQueryDone,
    accountingQueryOutput: accountingQueryOutput,
    accountingQuerySubmitPressed: accountingQuerySubmitPressed,
    currentReportURL: currentPDFURL,
    currentSource,
    doneAddToSavedPrompts: doneAddToSavedPrompts,
    handleAccountingQuerySubmit: handleAccountingQuerySubmit,
    handleAddToSavedPromptsButton: handleAddToSavedPromptsButton,
    boardMinuteDetails: [{ summary: "" }],
    queryHeaderText: queryHeaderText,
    queryInput: queryInput,
    queryType: queryType,
    queryTypes: queryTypes,
    queryValues,
    resetAnalyzerState: resetAnalyzerState,
    responseSource: responseSource,
    savedPrompts: savedPrompts,
    setCurrentPDFURL: setCurrentPDFURL,
    setCurrentSource,
    setQueryType: setQueryType,
    setQueryValues: setQueryValues,
    setSavedPrompts: setSavedPrompts,
    successfulFileUploads: successfulFileUploads,
    chunkLocations: chunkLocations,
    relevantFiles: relevantFiles,
  };

  const highlightPluginInstance = highlightUtil(
    highlightAreas,
    currentSource?.data?.url
  );

  const preActionForHandleURLSubmit = ({ url }) => {
    setFilesUploading(files => [...files, url]);
    setURLInputSubmitPressed(true);
    setAccountingQueryOutput([]);
    setAccountingQuerySubmitPressed(false);
    setAccountingQueryDone(false);
    setPDFDetails(null);
    setAddMoreFilesModalOpen(false);
    setFilesDropped(true);

    if (responseSource) {
      responseSource.removeEventListener("message");
      responseSource.close();
    }
  };
  const isUploading = filesUploading.length > 0;
  const isDataUploaded = successfulFileUploads.length > 0;

  const RenderFileUpload = () => (
    <FileUpload
      onDrop={onDrop}
      handleURLSubmit={(event) =>
        handleURLSubmit(event, {
          addNotification,
          beforeFinalAction({ url }) {
            setFilesParsing(files => removeFirstMatchingElement(files, file => file === url));
          },
          catchAction,
          NotificationType,
          parseFile: parseFile,
          postRequest,
          preAction: preActionForHandleURLSubmit,
          thenAction({ url }) {
            setFilesUploading(files => removeFirstMatchingElement(files, file => file === url));
            setFilesParsing(files => [...files, url]);
          },
          secureFilename,
          setFileIDs,
          setPDFDetails,
          setSuccessfulFileUploads,
          setURLInputSubmitPressed,
          urlInput,
          user,
          uuidv4,
        })
      }
      urlInput={urlInput}
    />
  );

  const RenderTitle = () => (
    <h1 className="text-2xl font-bold mb-5">
      <div className="flex justify-between items-center w-full">
        {Object.keys(appInstanceData).length === 0
          ? "fileType" in props
            ? props.fileType
            : "DocumentAI"
          : appInstanceData.custom_name}
      </div>
    </h1>
  );

  const RenderEdit = () => (
    <h1 className="text-2xl font-bold mb-5">
      <div className="flex justify-end items-center w-fulla">
        <Link
          to={`/app/edit/${appInstanceData.id}`}
          className="flex items-center pt-2 pb-2 px-4 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
        >
          Edit
        </Link>
      </div>
    </h1>
  );

  const UploadingText = () => (
    <p className="text-sm">
      Uploading <Typed strings={["..."]} loop typeSpeed={40} />
    </p>
  );

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <Transition.Root show={addMoreFilesModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={moreFilesCancelButtonRef}
          onClose={setAddMoreFilesModalOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <RenderFileUpload />
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setAddMoreFilesModalOpen(false)}
                    ref={moreFilesCancelButtonRef}
                  >
                    Cancel
                  </button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="grid grid-cols-12">
        {isDataUploaded && (
          <>
            <div className="col-span-7 flex flex-col justify-between">
              <RenderTitle />
              <div className="w-full flex-1">
                {currentSource && (
                  <div data-testid="rag-query-component" className="flex-1 p-4 pb-0 space-x-2 h-full">
                    <RagQueryComponent
                      analyzerContext={analyzerContext}
                      fileIDs={fileIDs}
                      jumpToPage={jumpToPage}
                      userObj={{ id: user.id }}
                      appInstanceId={appInstanceData.id}
                      originalUserText={queryInput?.current?.value}
                      generatedResponse={accountingQueryOutput}
                      setAccountingQueryDone={setAccountingQueryDone}
                      fetchSavedPrompts={fetchSavedPrompts}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-span-5">
              <RenderEdit />

              <div
                className="overflow-x-scroll"
                style={{ maxHeight: "calc(100vh - 200px)" }}
              >
                {successfulFileUploads.length === 0 && (<RenderFileUpload />)}

                <div className="mt-2">
                  <>
                    {/* {fileIDs.length > 0 && ( //THIS ABOVE IF NECESSARY */}
                    {/* {successfulFileUploads.map((fileUpload, index) => ( */}
                    {successfulFileUploads.length > 0 && (
                      <>
                        <SourcesTable
                          successfulFileUploads={successfulFileUploads}
                          currentPDFURL={currentPDFURL}
                          setCurrentPDFURL={setCurrentPDFURL}
                          currentSource={currentSource}
                          setCurrentSource={setCurrentSource}
                          fileType={fileType}
                          setSuccessfulFileUploads={setSuccessfulFileUploads}
                          urlInput={urlInput}
                          urlInputSubmitPressed={urlInputSubmitPressed}
                          shouldSkipResetAnalyzerState={shouldSkipResetAnalyzerState}
                        />
                        <button
                          className="text-gray-500 text-sm mt-2 hover:text-customHighlightColor bg-white"
                          onClick={() => setAddMoreFilesModalOpen(true)}
                        >
                          + Add more sources
                        </button>
                      </>
                    )}

                    {isUploading && (
                      <UploadingText />
                    )}
                    {filesParsing.length > 0 && (
                      <p className="text-sm my-2">
                        Parsing source{fileIDs.length > 1 ? "s" : null}
                        <Typed strings={["..."]} loop typeSpeed={40} />
                      </p>
                    )}

                    {filesParsing.length < 1 &&
                      currentSource && ( // EDIT THIS
                        <div className="flex flex-column">
                          <div className="flex-1 p-4">
                            {currentSource && (
                              <Viewer
                                fileUrl={currentSource.data["url"]}
                                plugins={[
                                  highlightPluginInstance,
                                  pageNavigationPluginInstance,
                                ]}
                              />
                            )}
                          </div>
                        </div>
                      )}
                  </>
                </div>
              </div>
            </div>
          </>
        )}
        {!isDataUploaded && (
          <div className="flex flex-col col-span-12 justify-center">
            <div className="flex flex-1 p-4 pb-0 space-x-2 h-full w-full justify-between items-center inline">
              <RenderTitle />
              <RenderEdit />
            </div>
            <div className="flex flex-col flex-1 p-4 pb-0 space-x-2 h-full w-full">
              <RenderFileUpload />
              {isUploading && (<UploadingText />)}
            </div>
          </div>
        )}
      </div>
    </Worker>
  );
};

export default DocumentAnalyzer;
