import React, { Suspense } from "react";
import { Routes, Route, useParams, useLocation } from "react-router-dom";
import { SignedIn, SignedOut, RedirectToSignIn } from "@clerk/clerk-react";

import Home from "../Home";
import ChatGPTStyleChat from "../apps/ChatGPTStyleChat";
import DocumentAI from "../apps/documentAI/DocumentAI";
import InvoiceCrossReference from "../apps/crossReference/InvoiceCrossReference";
import AuditChecklist from "../AuditChecklist";
import BoardMinutesAnalyzer from "../apps/boardMinutesAnalyzer/BoardMinutesAnalyzer";
import PCAOBAnalyzer from "../apps/pcaobAnalyzer/pcaobAnalyzer";
import SignIn from "../SignIn";
import Profile from "../Profile";
import Logs from "../admin/Logs";
import Feedback from "../admin/Feedback";
import Apps from "../Apps";
import MyApps from "../MyApps";
import CreateApp from "../appManipulation/CreateApp";
import EditApp from "../appManipulation/EditApp";
import AppDetail from "../AppDetail";
import GeneralDataChatAnalyzer from "../apps/generalDataChatAnalyzer/GeneralDataChatAnalyzer";
import DocumentAnalyzer from "../apps/documentAnalyzer/documentAnalyzer";
import ChatInterface from "../utils/ChatInterface";
import CompanyApps from "../CompanyApps";
import Changelog from "../screens/changelog/Changelog"
import PublishApp from "../appManipulation/PublishApp";
import FootnoteAI from "../apps/FootnoteAI/FootnoteAI";
import GenericExtractor from "../apps/GenericExtractor/GenericExtractor";
import FootnotesDatabase from "../apps/footnotesDatabase/FootnotesDatabase";

/**
 * Protects routes to ensure that only signed-in users can access them, redirecting
 * others to the sign-in page. It helps in removing the redundancy of checking
 * authentication status in every component.
 *
 * @param {{ children: JSX.Element }} props The child component(s) to be rendered within the protected route.
 * @returns {JSX.Element} A component that renders the children if the user is signed in, otherwise redirects to the sign-in page.
 */
const ProtectedRoute = ({ children }) => (
  <>
    <SignedIn>{children}</SignedIn>
    <SignedOut>
      <RedirectToSignIn redirectUrl="/" />
    </SignedOut>
  </>
);

const ProtectedRouteWithKey = ({children}) => {
  const { instance_id, conversation_uuid } = useParams();
  const key = `${instance_id}-${conversation_uuid}`
  return (
    <ProtectedRoute key={key}>
      {children}
    </ProtectedRoute>
  );

}

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/gaap/:instance_id?"
        element={
          <ProtectedRoute>
            <ChatGPTStyleChat
              title="US GAAP"
              description="Generally Accepted Accounting Principles (GAAP) is a framework of accounting standards, rules and procedures defined by the professional accounting industry, which has been adopted by nearly all publicly-traded U.S. companies."
              handbook="GAAP"
              sampleQuestions={[
                `What are the key principles of GAAP?`,
                `Give some recent changes to the GAAP standards.`,
                `What are the rules around 'Related Parties' disclosure?`,
                `What standard deals with the loss of sale on fixed assets?`,
                `What standard applies to companies in the insurance industry?`,
                `What are the accounting rules for investment funds?`,
                `Which standards deal with the treatment of inventory?`,
                `What is the appropriate accounting treatment for a sale and leaseback transaction?`,
              ]}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ifrs/:instance_id?"
        element={
          <ProtectedRoute>
            <ChatGPTStyleChat
              title="IFRS"
              description="International Financial Reporting Standards (IFRS) is a set of accounting standards developed by an independent, not-for-profit organization called the International Accounting Standards Board (IASB)."
              handbook="IFRS"
              sampleQuestions={[
                `What are the key principles of IFRS?`,
                `Give some recent changes to the IFRS standards.`,
                `What are the rules around 'Related Parties' disclosure?`,
                `What standard deals with the loss of sale on fixed assets?`,
                `What standard applies to companies in the insurance industry?`,
                `What are the accounting rules for investment funds?`,
                `Which standards deal with the treatment of inventory?`,
                `What is the appropriate accounting treatment for a sale and leaseback transaction?`,
              ]}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/us_individual_tax_copilot/:instance_id?"
        element={
          <ProtectedRoute>
            <ChatGPTStyleChat
              title="US Individual Tax Code"
              description="US Tax Code is a set of tax laws and regulations that are administered by the Internal Revenue Service (IRS)."
              handbook="USTAXINDIVIDUAL"
              sampleQuestions={[
                `Can you explain the process of filing a Form 1120 for a US corporation?`,
                `How does the IRS treat depreciation for corporate tax purposes and what methods can be used?`,
                `Can you explain the tax implications for corporations when distributing dividends to shareholders?`,
                `How does the tax code handle losses for corporations? Can they be carried forward or backward and if so, for how many years?`,
                `What are the specific tax filing requirements for S corporations and how do they differ from C corporations?`,
                `Can you explain the tax implications of a corporation issuing stock as a form of compensation?`,
                // `How does the Alternative Minimum Tax (AMT) apply to corporations and what triggers it?`,
                `What are the tax implications for a U.S. corporation with foreign income? How does the foreign tax credit work?`,
                `Can you explain the rules and tax implications for a corporation undergoing a merger or acquisition?`,
              ]}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/us_corporate_tax_copilot/:instance_id?"
        element={
          <ProtectedRoute>
            <ChatGPTStyleChat
              title="US Corporate Tax Code"
              description="US Tax Code is a set of tax laws and regulations that are administered by the Internal Revenue Service (IRS)."
              handbook="USTAXCORPORATE"
              sampleQuestions={[
                `Can you explain the process of filing a Form 1120 for a US corporation?`,
                `How does the IRS treat depreciation for corporate tax purposes and what methods can be used?`,
                `Can you explain the tax implications for corporations when distributing dividends to shareholders?`,
                `How does the tax code handle losses for corporations? Can they be carried forward or backward and if so, for how many years?`,
                `What are the specific tax filing requirements for S corporations and how do they differ from C corporations?`,
                `Can you explain the tax implications of a corporation issuing stock as a form of compensation?`,
                // `How does the Alternative Minimum Tax (AMT) apply to corporations and what triggers it?`,
                `What are the tax implications for a U.S. corporation with foreign income? How does the foreign tax credit work?`,
                `Can you explain the rules and tax implications for a corporation undergoing a merger or acquisition?`,
              ]}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/base_chat/:instance_id?"
        element={
          <ProtectedRoute>
            <ChatGPTStyleChat
              title="OpenAI GPT-4 Chat"
              description="Chat directly with OpenAI's GPT-4 large language model. This allows for quick comparison with our products."
              sampleQuestions={[
                `What are the key principles of IFRS and GAAP?`,
                `Give some recent changes to the IFRS and GAAP standards.`,
                `What are the rules around 'Related Parties' disclosure in IFRS and GAAP?`,
                `What standard deals with the loss of sale on fixed assets in IFRS and GAAP?`,
                `What standard applies to companies in the insurance industry in IFRS and GAAP?`,
                `What are the accounting rules for investment funds in IFRS and GAAP?`,
                `Which standards deal with the treatment of inventory in IFRS and GAAP?`,
                `What is the appropriate accounting treatment for a sale and leaseback transaction in IFRS and GAAP?`,
              ]}
            />
          </ProtectedRoute>
        }
      />
      {/* <Route path="/document_ai" element={<DocumentAI />} /> */}
      <Route
        path="/board_minutes_analyzer/:instance_id?"
        element={
          <ProtectedRoute>
            <BoardMinutesAnalyzer />
          </ProtectedRoute>
        }
      />
      {/* Using the key prop in ProtectedRoute to force the DocumentAI component to recreate when the route changes*/}
      <Route
        path="/contract_analyzer/:instance_id"
        element={
          <ProtectedRoute key="Contract Analyzer">
            <DocumentAnalyzer />
            {/* Contract Analyzer is identical to DocumentAnalyzer, so just using that here. Good to know. */}
            {/* TODO: We need to turn this into a subset of the new custom chatbot and then delete the DocumentAnalyzer entirely */}
          </ProtectedRoute>
        }
      />
      <Route
        path="/tenk_extractor/:instance_id"
        element={
          <ProtectedRoute key="Footnote Extractor">
            <GenericExtractor type="10k" />
          </ProtectedRoute>
        }
      />
      <Route
        path="/footnote_ai/:instance_id"
        element={
          <ProtectedRoute key="Footnote AI">
            <FootnoteAI />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pdf_tables_to_csvs/:instance_id"
        element={
          <ProtectedRoute key="All Tables">
            <GenericExtractor type="tables" />
          </ProtectedRoute>
        }
      />
      <Route
        path="/1099b_transactions_to_csv/:instance_id"
        element={
          <ProtectedRoute key="1099B Transactions">
            <DocumentAI fileType="1099B Transactions" />
          </ProtectedRoute>
        }
      />
      <Route
        path="/invoice_cross_reference/:instance_id?"
        element={
          <ProtectedRoute>
            <InvoiceCrossReference />
          </ProtectedRoute>
        }
      />
      <Route
        path="/audit_checklist/:instance_id?"
        element={
          <ProtectedRoute>
            <AuditChecklist />
          </ProtectedRoute>
        }
      />
      <Route
        path="/general_data_chat/:instance_id?/:conversation_uuid?"
        element={
          <ProtectedRouteWithKey>
            <GeneralDataChatAnalyzer fileType="General Data Chat" />
          </ProtectedRouteWithKey>
        }
      />
      {/* <Route
        path="/general_data_chat/:instance_id?/approved_qa"
        element={
          <ProtectedRouteWithKey>
            <ApprovedQuestionsAnswers />
          </ProtectedRouteWithKey>
        }
      /> */}
      <Route
        path="/pcaob_analzyer/:instance_id?"
        element={
          <ProtectedRouteWithKey>
            <PCAOBAnalyzer />
          </ProtectedRouteWithKey>
        }
      />
      <Route path="/apps_l1" element={<Apps layer={1} />} />
      <Route path="/apps_l2" element={<Apps layer={2} />} />
      <Route
        path="/myapps"
        element={
          <ProtectedRoute key="App Select">
            <MyApps />
          </ProtectedRoute>
        }
      />
      <Route
        path="/footnotes_database/:instance_id"
        element={
          <ProtectedRoute key="Footnotes Database">
            <FootnotesDatabase />
          </ProtectedRoute>
        }
      />
      <Route
        path="/company_apps"
        element={
          <ProtectedRoute key="Company App Select">
            <CompanyApps />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/create/:app"
        element={
          <ProtectedRoute key="Application">
            <CreateApp />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/edit/:instance_id"
        element={
          <ProtectedRoute key="Application Edit">
            <EditApp />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/publish/:app_instance_id"
        element={
          <ProtectedRoute key="Application Publish">
            <PublishApp />
          </ProtectedRoute>
        }
      />
      <Route
        path="/app/view/:app"
        element={
          <ProtectedRoute key="View Application">
            <AppDetail />
          </ProtectedRoute>
        }
      />
      <Route path="/sign_in" element={<SignIn />} />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin_users"
        element={
          <ProtectedRoute>
            <Logs />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin_feedback"
        element={
          <ProtectedRoute>
            <Feedback />
          </ProtectedRoute>
        }
      />
      <Route path="/chat_demo" element={<ChatInterface />}></Route>
      <Route path="/changelog" element={<Changelog />} />
    </Routes>
  );
};

export default Router;
