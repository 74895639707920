import { useState , useEffect } from 'react';
import { HandThumbUpIcon, HandThumbDownIcon } from '@heroicons/react/24/outline';
import { postRequest } from "./httpUtils";


function Feedback({ userObj, appInstanceId, originalUserText = "", generatedResponse }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [positiveNegative, setPositiveNegative] = useState(null);
  const [responseFeedback, setResponseFeedback] = useState("");
  const [thumbUpRecorded, setThumbUpRecorded] = useState(false);
  const [thumbDownRecorded, setThumbDownRecorded] = useState(false);
  const [textFeedbackRecorded, setTextFeedbackRecorded] = useState(false);

  const handleOpenModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsModalOpen(true);
    console.log("Opened mdoal")
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleIconFeedback = (iconFeedbackValue) => {
    console.log("Icon feedback", iconFeedbackValue);
    setPositiveNegative(iconFeedbackValue);
  };

  useEffect(() => {
    if (positiveNegative !== null) {
      handleSubmitFeedback();
    }
  }, [positiveNegative]);

  const handleSubmitFeedback = () => {
    console.log("Submitting feedback")
    console.log("User obj", userObj)
    console.log("App instance id", appInstanceId)
    console.log("Original user text", originalUserText)
    console.log("Generated response", generatedResponse)
    console.log("Positive negative", positiveNegative)
    console.log("Response feedback", responseFeedback)
    const feedbackData = {
      user_id : userObj,
      app_instance_id : appInstanceId,
      original_user_text : originalUserText,
      generated_response : generatedResponse,
      positive_negative : positiveNegative,
      response_feedback: responseFeedback
    };
    const endpointUrl = '/api/save_feedback'
    postRequest(endpointUrl, feedbackData)
      .then((response) => {
        if (isModalOpen) {
          setIsModalOpen(false);
        }
        setThumbUpRecorded(positiveNegative === "positive");
        setThumbDownRecorded(positiveNegative === "negative");
        setTextFeedbackRecorded(responseFeedback !== "");
        setPositiveNegative(null);
        setResponseFeedback("");
      })
      .catch((error) => {
        console.error('Error inserting into chat_feedback' , error, feedbackData);
      });
    setIsModalOpen(false);
  };

  return (
    <div className="feedback-component mb-1">
      <div className="flex justify-center gap-2">
        <HandThumbUpIcon
          className={`h-5 w-5 hover:text-customHighlightColor transition-colors cursor-pointer transform mt-1 ${
            thumbUpRecorded ? "text-customHighlightColor" : null
          }`}
          onClick={() => handleIconFeedback("positive")}
        />
        <HandThumbDownIcon
          className={`h-5 w-5 hover:text-customHighlightColor transition-colors cursor-pointer transform mt-1 ${
            thumbDownRecorded ? "text-customHighlightColor" : null
          }`}
          onClick={() => handleIconFeedback("negative")}
        />
        <button
          onClick={(event) => handleOpenModal(event)}
          className={`px-2 py-1 bg-gray-500 text-white text-xs rounded ${
            textFeedbackRecorded
              ? "cursor-not-allowed"
              : "hover:bg-customHighlightColor"
          }`}
          disabled={textFeedbackRecorded}
        >
          {!textFeedbackRecorded ? "Add Feedback" : "Thanks!"}
        </button>
      </div>

      {isModalOpen && (
        <div className="modal-backdrop bg-black bg-opacity-50 fixed inset-0 flex items-center justify-center">
          <div className="modal-content bg-white p-4 rounded-lg shadow-lg max-w-md">
            <textarea
              value={responseFeedback}
              onChange={(e) => setResponseFeedback(e.target.value)}
              className="textarea p-4 w-full mb-4"
              placeholder="Let us know what you like and don't like about the response. Was it correct? Was it precise? Was it too long or too short?"
            ></textarea>
            <div className="flex justify-center gap-2">
              <button
                onClick={handleSubmitFeedback}
                className="submit-feedback-btn bg-gray-500 hover:bg-customHighlightColor text-white font-bold py-2 px-4 rounded"
              >
                Submit Feedback
              </button>
              <button
                onClick={handleCloseModal}
                className="close-modal-btn bg-gray-500 hover:bg-customHighlightColor text-white font-bold py-2 px-4 rounded"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Feedback;