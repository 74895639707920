// import useInstallApp from "../hooks/useInstallApp";

import { Link } from "react-router-dom";
import React from "react";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";

const AppCard = ({ card, url, cta_text }) => {
  return (
    <div
      data-testid="card-item"
      className="group p-4 m-2 border-lightgray border-1 rounded-lg transition-colors duration-300 flex flex-col space-y-2 min-w-80 h-300 text-customDarkGray"
    >
      <div className="flex w-10 p-2 bg-blue-50">
        <DocumentMagnifyingGlassIcon className="h-6 w-6 text-blue-500" />
      </div>
      <div className="flex items-start w-full flex-grow">
        <div data-testid="card-item-name" className="flex-1 text-lg font-medium break-words text-gray-700">
          {card.name}
        </div>
      </div>
      <div className="w-full text-sm py-1 text-gray-500">
        {card.description}
      </div>
      <div className="flex flex-col items-start w-full flex-grow text-sm border-b border-lightgray">
        <p>
          <CalendarDaysIcon className="h-5 w-5 p-1 mr-2 text-blue-500 inline bg-blue-50 rounded" />{" "}
          Published: March 2024
        </p>
      </div>
      <Link
        data-testid="card-item-go-btn"
        className="flex items-center pt-2 pb-2 font-normal rounded border-customHighlightColor text-customHighlightColor border-1 cursor-pointer justify-center no-underline hover:bg-customHighlightColor hover:text-customLightGray transition-colors duration-300"
        to={url}
      >
        <ArrowDownTrayIcon className="w-4 h-4 mr-1" /> {cta_text}
      </Link>
    </div>
  );
};

export default AppCard;
