import React from 'react';

const ChatInterface = () => {
  const messages = [
    { id: 1, text: "Welcome! I'm here to assist with any questions or inquiries you may have. Feel free to ask anything you need.", sender: 'bot' },
    { id: 2, text: "Can you show me how to customize my settings?", sender: 'user' },
    { id: 3, text: "Certainly! To customize, select the gear icon atop the page.", sender: 'bot' },
    // Add more messages as needed for the example
  ];

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <div className="flex flex-col overflow-y-auto p-4 space-y-4 flex-grow">
        {/* Message list */}
        {messages.map((message) => (
          <div key={message.id} className={`w-64 p-4 rounded-lg shadow ${message.sender === 'bot' ? 'self-start' : 'text-black self-end'}`}>
            <p className="text-sm">{message.text}</p>
          </div>
        ))}
      </div>
      {/* Input area */}
      <div className="border-t-2 border-gray-200 p-3">
        <div className="flex items-center space-x-2">
          <input
            className="flex-grow p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            type="text"
            placeholder="Type a message"
            // This input is for display only and is not functional without state
            disabled
          />
          <button
            className="bg-blue-500 text-white p-2 rounded-lg focus:outline-none hover:bg-blue-600"
            // This button is for display only and is not functional without state
            disabled
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatInterface;
