const QuestionCard = ({ question, fillAndSubmitQuestion }) => {
  return (
    <div
      key={question}
      className="cursor-pointer shadow border border-transparent hover:border-gray-800 p-4 rounded bg-white flex items-center justify-center transition duration-300 ease-in-out w-36 m-2"
      onClick={(e) => {
        e.preventDefault();
        fillAndSubmitQuestion(question);
      }}
    >
      <a
        href="#"
        className="text-gray-600 hover:text-gray-800 no-underline text-center"
      >
        <i>"{question}"</i>
      </a>
    </div>
  );
};

export default QuestionCard;
