import React, { useState, useEffect, useMemo } from "react";
import { postRequest } from "../../utils/httpUtils";
import {  PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";

import cn from "classnames";
import Carousel from "react-multi-carousel";
import Table from "./Table";
import { ClockIcon,FunnelIcon,CheckCircleIcon } from '@heroicons/react/24/outline';
import "react-multi-carousel/lib/styles.css";
import { getBaseURL } from "../../utils/utils";
import getSuggestedFormatAndReason from "./getSuggestedFormatAndReason";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const ChooseTemplate = ({
  templates,
  selectedTemplate,
  setSelectedTemplate,
}) => {
  const fileIDs = templates?.map((template) => template.data.file_id);

  const [footnotes, setFootnotes] = useState([]);
  const templateObjects = useMemo(() => {
    if (!footnotes) return [];
    const returnValue = [];
    for (const footnoteObject of footnotes) {
      const filename = footnoteObject.filename;
      for (const singleFootnote of footnoteObject.footnotes) {
        const footnoteTitle = singleFootnote.footnote.title.toLowerCase();
        if (footnoteTitle.includes("lease")) {
          returnValue.push({
            filename,
            footnote_id: singleFootnote.footnote_id,
            screenshot_file_url: `${getBaseURL()}/api/files/${singleFootnote.screenshot_file_id}`,
          });
        }
      }
    }
    return returnValue;
  }, [footnotes]);

  const imgSrc = useMemo(() => {
    const indexOfSelectedTemplate = templateObjects.findIndex(t => t.footnote_id === selectedTemplate);
    const templateObject = templateObjects[indexOfSelectedTemplate] || templateObjects[0];
    return templateObject?.screenshot_file_url;
  }, [selectedTemplate, templateObjects]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await postRequest("/api/footnote_ai/get_footnotes", {
          file_ids: fileIDs,
        });
        setFootnotes(response.data.data);

        console.log(response.data);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      }
    };

    fetchData();
  }, [templates]);

  const footnotesFormattedForTable = useMemo(() =>footnotes.flatMap((file) => {
    return file.footnotes
      ?.filter((footnote) =>
        footnote?.footnote?.title.toLowerCase().includes("lease".toLowerCase())
      )
      .map((f) => {
        console.log("SUGGESTED TEMPLATES");
        console.log(f);
        return {
          row_data: [
            `${file.filename}, page ${f.footnote.top.page_number}`,
            f.footnote.title,
          ],
          metadata: {
            footnote_id: f.footnote_id,
            bgcolor: selectedTemplate === f.footnote_id ? "green" : "white",
          },
        };
      });
  }), [footnotes, selectedTemplate]);

  const [format, reason] = useMemo(() => {
    if (!selectedTemplate) return getSuggestedFormatAndReason(null);
    const templateObject = templateObjects.find(
      (t) => t.footnote_id === selectedTemplate
    );
    return getSuggestedFormatAndReason(templateObject?.filename);
  }, [selectedTemplate, templateObjects]);

  return (
    <>
      <div className="flex flex-col items-baseline md:flex-row p-6 min-h-screen">
        {/* Left Section - Suggested Footnotes */}
        <div className="w-full md:w-2/4 bg-white p-4 shadow-lg rounded-md">
          <div className="flex items-center justify-between mb-2">
          <div className="flex flex-col w-full">
          <div className="flex items-center ">
            <h3 className="text-lg font-bold text-customBlack">Suggested Templates</h3>
            <div className="flex items-center bg-blue-50 text-blue-500 text-sm font-semibold px-2 py-1 rounded-md">
                <ClockIcon className="w-4 h-4 mr-1" /> In progress
                </div>
              </div>
              <div className="flex items-center justify-between mt-2">
                <div className="relative w-full bg-customLightGray h-1 rounded">
                  <div className="bg-customHighlightColor h-1 rounded" style={{ width: '20%' }}></div>
                </div>
                <span className="text-sm text-customDarkGray ml-2">(3/15)</span>
              </div>
            {/* <div className="flex items-center space-x-3">
              <FunnelIcon className="w-6 h-6 text-gray-500 cursor-pointer" />
              <PlusIcon className="w-6 h-6 text-blue-500 cursor-pointer" />
              
              </div> */}
           
            </div>
          </div>

          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center space-x-2">
              <button className="text-sm text-customHighlightColor border border-customHighlightColor bg-customHighlightColor/10 rounded-md px-2 py-1">15 All</button>
              <button className="text-sm text-customDarkGray border border-gray-300 rounded-md px-2 py-1">03 Approved</button>
              <button className="text-sm text-customDarkGray border border-gray-300 rounded-md px-2 py-1">12 Awaiting approval</button>
            </div>
            <div className="flex items-center space-x-3">
              <FunnelIcon className="w-6 h-6 text-customHighlightColor cursor-pointer"  />
              <PlusCircleIcon className="w-7 h-7 text-customHighlightColor cursor-pointer" />
            </div>
          </div>

          {/* Disclosure List */}
          <div className="w-full mt-6">
            <Table
              colNames={["Suggested Template", "Title"]}
              rows={footnotesFormattedForTable}
              itemsPerPage={20}
              onClickRow={(row, key) => {
                console.log("XG onClickRow", row.metadata.footnote_id);
                setSelectedTemplate(row.metadata.footnote_id);
              }}
            />
          </div>
        </div>

        {/* Right Section - Details Panel */}
        <div className="w-full md:w-2/3 bg-white p-4 shadow-lg rounded-md md:ml-4 mt-4 md:mt-0">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-lg font-bold text-customBlack">Footnote 01</h4>
            <div className="flex items-center space-x-1">
              <span className="text-sm text-blue-500 cursor-pointer">
                <XMarkIcon className="w-6 h-6 cursor-pointer text-customHighlightColor" />
              </span>
            </div>
          </div>

          {/* Template Preview */}
          <div className="mt-4 mb-6">
          <p className="text-base text-customBlack font-semibold">Template Model</p>
            <div className="h-96 rounded-lg mb-4 flex flex-col justify-center items-center">
              <img
                className="h-96 rounded-lg"
                src={imgSrc}
              />
            </div>

            <Carousel
              responsive={responsive}
              removeArrowOnDeviceType={"mobile"}
              renderArrowsWhenDisabled
              arrows
            >
              {templateObjects.map(({footnote_id: footnoteID, screenshot_file_url: screenshotFileURL}) => (
                <div
                  key={footnoteID}
                  onClick={() => setSelectedTemplate(footnoteID)}
                  className={cn(
                    "relative mx-2 rounded-lg cursor-pointer border-2 border-gray-100 flex flex-col justify-center items-center",
                    {
                      "border-customHighlightColor":
                        footnoteID == selectedTemplate,
                    }
                  )}
                >
                  <img className="rounded-lg h-20" src={screenshotFileURL} />
                  {footnoteID == selectedTemplate && (
                    <div className="h-9 w-9 border bg-white border-customHighlightColor rounded-lg absolute flex flex-col justify-center items-center">
                      <CheckCircleIcon className="h-6 w-6 text-customHighlightColor" />
                    </div>
                  )}
                </div>
              ))}
            </Carousel>
          </div>

          {/* Template Description */}
          <div className="mt-4 mb-6">
          <p className="text-base text-customBlack font-semibold">
              Suggested Format
            </p>

            <div className="bg-gray-50 p-4 mt-2 rounded-md border-l-4 border-gray-500">
              <p className="text-sm text-customDarkGray font-semibold">
                {format}
              </p>
            </div>
          </div>

          <div className="mt-4 mb-6">
          <p className="text-base text-customBlack font-semibold">Reason</p>

          <div className="bg-gray-50 p-4 mt-2 rounded-md border-l-4 border-gray-500">
          <p className="text-sm text-customDarkGray font-semibold">
                {reason}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseTemplate;
