import React from 'react';
import AppCard from './AppCard'; // Assuming AppCard is the same component used in MyApps.js

const AppsList = ({ pageName, appsArray }) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="bg-white">
        <h1 className="text-2xl font-bold">{pageName}</h1>
        {/* <p className="mb-5">{props.description}</p> */}
      </div>
      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
        {Array.isArray(appsArray) && appsArray.map((card) => (
          <AppCard
            key={card.app_instance_id}
            card={card}
            url={card.url}
            cta_text="Go"
          />
        ))}
      </div>
    </div>
  );
};

export default AppsList;