import React from "react";
import { getBaseURL } from "../../utils";
import { TrashIcon } from "@heroicons/react/24/solid";
import { postRequest } from "../../utils/httpUtils";

const FilesTable = ({ files, fetchFootnoteFiles, fetchFootnotes }) => {
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="px-1 py-1 text-left text-sm font-semibold text-gray-900"
          >
            Filename
          </th>
          <th
            scope="col"
            className="px-1 py-1 text-left text-sm font-semibold text-gray-900"
          >
            Delete
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {files &&
          files.length > 0 &&
          files.map((file) => {
            const fileFormat =
              file.file_format || file.filename.split(".").pop();
            return (
              <tr key={file.file_id}>
                <td className="whitespace-nowrap px-1 py-1 text-sm text-gray-500">
                  <a
                    href={`${getBaseURL()}/api/files/${
                      file.file_id
                    }.${fileFormat}`}
                    target="_blank"
                  >
                    {file.filename}
                  </a>
                </td>
                <td className="whitespace-nowrap px-1 py-1 text-sm text-gray-500">
                  <button
                    href={`${getBaseURL()}/api/files/${
                      file.file_id
                    }.${fileFormat}`}
                    target="_blank"
                    onClick={async () => {
                      try {
                        const response = await postRequest("/api/delete", {
                          file_id: file.file_id,
                        });
                        fetchFootnotes()
                        fetchFootnoteFiles()

                      } catch (error) {
                        if (error.response) {
                          console.log(error.response.data);
                          console.log(error.response.status);
                          console.log(error.response.headers);
                        } else if (error.request) {
                          console.log(error.request);
                        } else {
                          console.log("Error", error.message);
                        }
                        console.log(error.config);
                      }
                    }}
                  >
                    <TrashIcon className="w-5 h-5 mr-1" />
                  </button>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default FilesTable;
