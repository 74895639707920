/**
 * Reusable Tab component
 * @param {Object} props 
 * @param {Object[]} props.tabs - Array of tab objects, each containing `id` and `label`.
 * @param {string} props.currentTab - The currently active tab `id`.
 * @param {function} props.onClickTab - Callback function triggered on tab click. Receives the clicked tab object as parameter.
 * @returns {JSX.Element}
 */
const Tabs = ({ tabs, currentTab, onClickTab }) => {
    return (
      <div className="flex border-b border-gray-300">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => onClickTab(tab)}
            className={`py-2 px-6 text-sm font-medium focus:outline-none ${
              currentTab === tab.id
                ? 'border-b-4 border-blue-600 text-customBlack' // active tab styles
                : 'text-customDarkGray hover:text-customBlack'
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>
    );
  }
  
  export default Tabs;
  